import React, { useEffect, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { QAttachment, useToastProvider } from '@qualio/ui-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { attachmentsApi } from '../../../api/attachmentsApi';
import { SearchElementType } from '../../../components/SearchField/SearchField';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { useQualityConfigs } from '../../../context/qualityConfigs';
import { AttachmentEventsHelper } from '../../../lib/attachments/attachmentEventsHelper';
import { retrieveNativeTestCases, retrieveRequirements } from '../../../lib/designControlNativeEnabled';
import { handleValidationErrors } from '../../../lib/formValidationUtilities';
import { QualityTypeConfigWithType } from '../../../types/qualityTypeConfig';
import { testCaseSearchDesignElementsMap } from '../assets/NativeTestCase.constants';
import { NativeTestCasePresentationalControllerName } from '../assets/NativeTestCase.enums';
import {
  LinkedDesignElementType,
  NativeTestCaseContainerFormSchema,
  NativeTestCaseContainerFormType,
  TestCaseLinkedDesignElementsType,
  TestCasePayload,
  TestCaseSegmentedControlType,
  TestCaseStatusType,
} from '../assets/NativeTestCase.zod';
import { DesignElementConfigurationsType, NativeTestCaseContainerProps } from '../types/NativeTestCase.types';
import { toDefaultTestCaseFormValue, toTestCaseSegmentedControlType } from '../utils/NativeTestCase.utils';
import NativeTestCasePresentational from './NativeTestCasePresentational';

const NativeTestCaseContainer: React.FC<NativeTestCaseContainerProps> = ({
  product,
  title,
  confirmText,
  testCaseId,
  testCaseCode,
  isSubmittingPayload,
  submitTestCasePayload,
  cancelCreateTestCase,
  validationErrors,
  initialValues,
  testResult,
}) => {
  const { configs, loading } = useQualityConfigs();
  const companyId = useCompanyId();
  const existingAttachments: Array<QAttachment> = initialValues?.attachments ?? [];
  const [attachments, setAttachments] = useState<Array<QAttachment>>(existingAttachments);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const { showToast } = useToastProvider();

  const { nativeTestCases, allRequirements } = useMemo<DesignElementConfigurationsType>(() => {
    return {
      nativeTestCases: retrieveNativeTestCases(configs),
      allRequirements: retrieveRequirements(configs),
    };
  }, [configs]);

  const testCaseSegmentedControlTypes = useMemo<
    Array<NativeTestCaseContainerFormType[NativeTestCasePresentationalControllerName.TYPE]>
  >(() => nativeTestCases.filter(Boolean).map(toTestCaseSegmentedControlType), [nativeTestCases]);

  const formMethods = useForm<NativeTestCaseContainerFormType>({
    defaultValues: initialValues || toDefaultTestCaseFormValue(testCaseSegmentedControlTypes[0]),
    mode: 'onChange',
    resolver: zodResolver(NativeTestCaseContainerFormSchema),
  });

  useEffect(
    () =>
      handleValidationErrors(formMethods.setError as any, validationErrors, '', {
        statusLabel: 'statusLabel.label',
        type: 'type.type',
      }),
    [formMethods.setError, validationErrors],
  );

  const segmentedControlTypeFormState: TestCaseSegmentedControlType = useWatch({
    name: NativeTestCasePresentationalControllerName.TYPE,
    control: formMethods.control,
  }) as TestCaseSegmentedControlType;

  const initialCurrentTypeInForm = useMemo(() => {
    if (!nativeTestCases || !nativeTestCases.length) {
      return undefined;
    }

    return toTestCaseSegmentedControlType(nativeTestCases[0]);
  }, [nativeTestCases]) as TestCaseSegmentedControlType;

  const testCaseStatuses = useMemo<Array<TestCaseStatusType>>(() => {
    const currentTypeInForm = segmentedControlTypeFormState ? segmentedControlTypeFormState : initialCurrentTypeInForm;

    const currentTestCase = nativeTestCases.find((testCase) => testCase.label === currentTypeInForm.title);
    if (!currentTestCase?.workflow) {
      return [];
    }

    return currentTestCase.workflow.states.map((item) => ({
      label: item.name,
      value: item.name,
    }));
  }, [nativeTestCases, segmentedControlTypeFormState, initialCurrentTypeInForm]);

  const relatedRequirementSearchField: QualityTypeConfigWithType | undefined = useMemo<
    QualityTypeConfigWithType | undefined
  >(() => {
    const currentTypeInForm = segmentedControlTypeFormState ? segmentedControlTypeFormState : initialCurrentTypeInForm;
    if (!currentTypeInForm) {
      return;
    }
    const requirementSearchField = testCaseSearchDesignElementsMap[currentTypeInForm.type];
    return allRequirements.find((requirement) => requirement.type === requirementSearchField);
  }, [allRequirements, segmentedControlTypeFormState, initialCurrentTypeInForm]);

  const deleteSearchedDesignElement = (
    requirementKey: keyof NativeTestCaseContainerFormType,
    searchedDesignElement: SearchElementType,
  ) => {
    const requirementValues = [...(formMethods.getValues()[requirementKey] as SearchElementType[])];
    const index = requirementValues.findIndex((requirement) => requirement.label === searchedDesignElement.label);
    requirementValues.splice(index, 1);
    formMethods.setValue(requirementKey, requirementValues);
  };

  const resetFormValuesOnTestCaseTypeChange = () => {
    formMethods.setValue('statusLabel', {
      label: '',
      value: '',
    });
    formMethods.setValue('req1', []);
    formMethods.setValue('req2', []);
    formMethods.setValue('req3', []);
    formMethods.setValue('category', { qri: '', label: '' });
  };

  const confirmCreateTestCase = async () => {
    const formValues = formMethods.getValues();
    const linkedDesignElements =
      formValues[relatedRequirementSearchField?.type as keyof TestCaseLinkedDesignElementsType] || [];
    const requirements = linkedDesignElements.filter(Boolean).map((item: LinkedDesignElementType) => item.value);
    const testCasePayload: TestCasePayload = {
      title: formValues.title,
      type: formValues.type.type,
      description: formValues.description,
      statusLabel: formValues.statusLabel.value,
      requirements: requirements,
      category: !!formValues.category?.qri ? formValues.category : undefined,
    };
    testCasePayload.attachments = attachments;

    await submitTestCasePayload(testCasePayload);
  };

  const onAttachmentAdd = (attachment: QAttachment) => {
    setAttachments([...attachments, attachment]);
    return Promise.resolve();
  };

  const onAttachmentRemove = (fileId: string) => {
    setAttachments(attachments.filter((a) => a.id !== fileId));
    return Promise.resolve();
  };

  const resourceIdentifier = useMemo<string | undefined>(() => {
    if (!configs || !configs.length) {
      return;
    }

    const type = segmentedControlTypeFormState ? segmentedControlTypeFormState.type : initialCurrentTypeInForm?.type;
    return configs?.find((config) => config.type === type)?.category?.at(0)?.resourceIdentifier;
  }, [segmentedControlTypeFormState, initialCurrentTypeInForm, configs]);

  return (
    <FormProvider {...formMethods}>
      <NativeTestCasePresentational
        title={title}
        confirmText={confirmText}
        product={product}
        isLoading={loading}
        testCaseCode={testCaseCode}
        testResult={testResult}
        testCaseSegmentedControlTypes={testCaseSegmentedControlTypes}
        testCaseStatuses={testCaseStatuses}
        relatedRequirement={relatedRequirementSearchField}
        isSegmentedControlDisabled={initialValues !== undefined}
        resetFormValuesOnTestCaseTypeChange={resetFormValuesOnTestCaseTypeChange}
        cancelCreateTestCase={cancelCreateTestCase}
        confirmCreateTestCase={formMethods.handleSubmit(confirmCreateTestCase)}
        deleteLinkedRequirements={deleteSearchedDesignElement}
        formErrors={formMethods.formState.errors}
        isSubmittingPayload={isSubmittingPayload}
        existingAttachments={existingAttachments}
        isBusy={isBusy}
        setIsBusy={setIsBusy}
        uploadInfoProvider={attachmentsApi.uploadInfoProvider(companyId, product.id)}
        onAttachmentAdd={onAttachmentAdd}
        onAttachmentClick={(attachmentId: string) => {
          attachmentsApi.downloadAttachment(companyId, product.id, attachments, attachmentId);
          return Promise.resolve();
        }}
        onAttachmentWarning={(warning) => AttachmentEventsHelper.handleWarning(warning, showToast)}
        onAttachmentRemove={onAttachmentRemove}
        resourceIdentifier={resourceIdentifier}
        testCaseType={formMethods.getValues().type?.type}
      />
    </FormProvider>
  );
};

export default NativeTestCaseContainer;
