import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";

import { designControlsApi } from "../api/designControls";

export const useRetrieveUsers = () => {
  const { companyId } = useCurrentUser();

  return useQuery({
    queryKey: ["USER_LIST", companyId],
    queryFn: () => designControlsApi.retrieveUsers(companyId),
  });
};
