import React from 'react';
import { QStack, QBox } from '../../QLayouts';
import { QLink } from '../../QAtoms';
import { QIcon } from '../../QIcons';
import { SearchDomain } from '../../types/domains/Search';
import { IconNameType } from '../../types/Icons';
import { DataProps } from '../../utils';

export const domainIconMap = {
  users: 'ResourceUsers',
  'quality-events': 'ResourceEvents',
  documents: 'ResourceDocuments',
  'change-management': 'ResourceCR',
  suppliers: 'ResourceSuppliers',
  'change-requests': 'ResourceCR',
  registry: 'ResourceRegistry',
  'design-controls': 'ResourceDesignControls',
} satisfies { [key in SearchDomain]: IconNameType };

export type QResourceLabelProps = {
  domain: SearchDomain;
  link?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
} & DataProps;

/**
 * This components displays informations about linked Qualio object e.g. Event, Document, Supplier, User.
 */
export const QResourceLabel: React.FC<QResourceLabelProps> = ({
  domain,
  link,
  openInNewTab,
  children,
  onClick,
  ...dataProps
}) => (
  <QStack direction="row" spacing="8px" alignItems="center" {...dataProps}>
    <QIcon iconName={domainIconMap[domain]} />
    <QBox>
      {link === undefined ? (
        children
      ) : (
        <QLink isExternal={openInNewTab} href={link} onClick={onClick}>
          {children}
        </QLink>
      )}
    </QBox>
  </QStack>
);
