import React, { useState } from "react";

import {
  createQColumnHelper,
  DataProvider,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QStack,
  QTag,
} from "@qualio/ui-components";

import { TestCaseDesignElement } from "@design-controls/types";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { ItemStatusTag } from "../ItemStatusTag/ItemStatusTag";

const columnHelper = createQColumnHelper<TestCaseDesignElement>();

type Props = {
  testCases: TestCaseDesignElement[];
};

export const TestCaseTable: React.FC<Props> = ({ testCases }) => {
  const [expanded, setExpanded] = useState(true);
  const configs = useCurrentConfigs();

  const columns = [
    columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
    columnHelper.text("title", { header: "Title", minWidth: "200px" }),
    columnHelper.text((record) => record.category?.label, {
      header: "Category",
      maxWidth: "150px",
    }),
    {
      header: "Type",
      meta: {
        maxWidth: "150px",
      },
      cell: ({
        row: { original: row },
      }: {
        row: { original: TestCaseDesignElement };
      }) => {
        return (
          <QTag variantColor="gray">
            {
              (
                configs.find((config) => config.type === row.type) as
                  | { label: string }
                  | undefined
              )?.label
            }
          </QTag>
        );
      },
    },
    {
      header: "Status",
      meta: {
        maxWidth: "150px",
      },
      cell: ({
        row: { original: row },
      }: {
        row: { original: TestCaseDesignElement };
      }) => {
        return <ItemStatusTag itemStatus={row.itemStatus} />;
      },
    },
  ];

  if (!testCases.length) {
    return null;
  }

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Test cases
        </QHeading>
        <QIconButton
          onClick={() => setExpanded((s) => !s)}
          iconName={expanded ? "ChevronUp" : "ChevronDown"}
          aria-label={expanded ? "Collapse test cases" : "Expand test cases"}
        />
      </QStack>
      <QCollapse isOpen={expanded}>
        <DataProvider.Fixed data={testCases}>
          <QDataTable
            columns={columns}
            hideItemCount
            data-cy="test-case-list"
          />
        </DataProvider.Fixed>
      </QCollapse>
    </QCard>
  );
};
