import { QTag, QTagProps } from "@qualio/ui-components";
import React from "react";

type Props = {
  itemStatus: string;
};

export const ItemStatusTag: React.FC<Props> = ({ itemStatus }) => {
  const variantColor: Record<string, QTagProps["variantColor"]> = {
    NEW: "blue",
    CHANGED: "orange",
    DELETED: "red",
  };

  return <QTag variantColor={variantColor[itemStatus]}>{itemStatus}</QTag>;
};
