import { Icon } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import * as icons from 'react-icons/fi';
import {
  BsFillCheckCircleFill,
  BsExclamationCircleFill,
  BsFillExclamationTriangleFill,
  BsFillXCircleFill,
} from 'react-icons/bs';
import { IconNameType } from '../types/Icons';
import { CheckCircleFilled } from './CheckCircleFilled';
import { OneDrive } from './OneDrive';
import { QualioAI } from './QualioAI';
import { ResourceCR } from './ResourceCR';
import { ResourceDocuments } from './ResourceDocuments';
import { ResourceEvents } from './ResourceEvents';
import { ResourceRegistry } from './ResourceRegistry';
import { ResourceSuppliers } from './ResourceSuppliers';
import { ResourceUsers } from './ResourceUsers';
import { Tools } from './Tools';
import Qualio from './Qualio';
import { Events } from './Events';
import BellNotification from './BellNotification';
import Bell from './Bell';
import Settings from './Settings';
import SettingsNotification from './SettingsNotification';
import { UserCircle } from './UserCircle';
import { ResourceDesignControls } from './ResourceDesignControls';
import { MessageCheck } from './MessageCheck';
import { Related } from './Related';
import { Section } from './Section';
import { YesNo } from './YesNo';
import { SingleLine } from './SingleLine';
import { DropDown } from './DropDown';

export type QIconProps = {
  iconName: IconNameType;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: string;
};

function getSize(variant?: string) {
  switch (variant) {
    case 'xs':
      return 3;
    case 'md':
      return 5;
    case 'lg':
      return 6;
    default:
      return 4;
  }
}

const LUT: Readonly<{
  [key in IconNameType]?: React.FC<Partial<QIconProps>>;
}> = {
  Bell: Bell,
  BellNotification: BellNotification,
  CheckCircleFilled: CheckCircleFilled,
  DropDown: DropDown,
  OneDrive: OneDrive,
  Qualio: Qualio,
  QualioAI: QualioAI,
  Tools: Tools,
  Events: Events,
  MessageCheck: MessageCheck,
  Related: Related,
  ResourceCR: ResourceCR,
  ResourceDocuments: ResourceDocuments,
  ResourceEvents: ResourceEvents,
  ResourceRegistry: ResourceRegistry,
  ResourceDesignControls: ResourceDesignControls,
  ResourceSuppliers: ResourceSuppliers,
  ResourceUsers: ResourceUsers,
  UserCircle: UserCircle,
  Section: Section,
  Settings: Settings,
  SettingsNotification: SettingsNotification,
  SingleLine: SingleLine,
  YesNo: YesNo,
} as const;

export const QIcon: React.FC<QIconProps> = ({
  iconName,
  color,
  size: rawSize,
}) => {
  const size = getSize(rawSize);
  const props = {
    color,
    width: size,
    height: size,
  } as const;

  const QualioIcon = LUT[iconName];
  if (QualioIcon) {
    return <QualioIcon {...props} />;
  }

  switch (iconName) {
    // [Start] BS Icons
    // We avoid importing the icons via wildcard because it would massively
    // increase the bundle size. Therefore, we need a case per-icon.
    case 'FillCheckCircleFill':
      return <Icon {...props} as={BsFillCheckCircleFill} />;
    case 'ExclamationCircleFill':
      return <Icon {...props} as={BsExclamationCircleFill} />;
    case 'FillExclamationTriangleFill':
      return <Icon {...props} as={BsFillExclamationTriangleFill} />;
    case 'FillXCircleFill':
      return <Icon {...props} as={BsFillXCircleFill} />;
    // [END] BS Icons
    default:
      return <Icon {...props} as={icons[`Fi${iconName}`]} />;
  }
};
QIcon.displayName = 'QIcon';

export const getIcon = (iconName: IconNameType): IconType => {
  switch (iconName) {
    case 'FillCheckCircleFill':
      return BsFillCheckCircleFill;
    case 'ExclamationCircleFill':
      return BsExclamationCircleFill;
    case 'FillExclamationTriangleFill':
      return BsFillExclamationTriangleFill;
    case 'FillXCircleFill':
      return BsFillXCircleFill;
    default:
      return icons[`Fi${iconName}`];
  }
};
