import React from "react";

import { QNotFound } from "@qualio/ui-components";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { CurrentProductProvider } from "./context/currentProduct";
import { ListLayout } from "./Layout/ListLayout";
import { RiskListLayout } from "./Layout/RiskListLayout";
import { CreateProduct } from "./views/CreateProduct/CreateProduct";
import { Documents } from "./views/Documents/Documents";
import { FMEARiskList } from "./views/FMEARiskList/FMEARiskList";
import { ISORiskList } from "./views/ISORiskList/ISORiskList";
import { Products } from "./views/Products/Products";
import { RequirementList } from "./views/RequirementList/RequirementList";
import { ReviewList } from "./views/ReviewList/ReviewList";
import { TestList } from "./views/TestList/TestList";
import { EditRequirement } from "./views/EditRequirement/EditRequirement";
import { RequirementOverview } from "./views/RequirementOverview/RequirementOverview";

export const App: React.FC = () => {
  return (
    <BrowserRouter basename="/design-controls">
      <Routes>
        <Route
          path="product/:product"
          element={
            <CurrentProductProvider>
              <Outlet />
            </CurrentProductProvider>
          }
        >
          <Route
            path="requirement"
            element={
              <ListLayout>
                <RequirementList />
              </ListLayout>
            }
          />
          <Route
            path="requirement/:selectedItemId"
            element={<RequirementOverview />}
          />
          <Route
            path="requirement/:selectedItemId/edit"
            element={<EditRequirement />}
          />
          <Route
            path="risk"
            element={
              <ListLayout>
                <RiskListLayout />
              </ListLayout>
            }
          >
            <Route path="iso" element={<ISORiskList />} />
            <Route path="fmea" element={<FMEARiskList />} />
          </Route>
          <Route
            path="test-case"
            element={
              <ListLayout>
                <TestList />
              </ListLayout>
            }
          />
          <Route
            path="document"
            element={
              <ListLayout>
                <Documents />
              </ListLayout>
            }
          />
          <Route
            path="change-control"
            element={
              <ListLayout>
                <ReviewList />
              </ListLayout>
            }
          />
          <Route index element={<Navigate to="requirement" />} />
          <Route path="*" element={<QNotFound />} />
        </Route>

        <Route path="product/:productId/edit" element={null} />
        <Route path="product/create" element={<CreateProduct />} />
        <Route path="product" element={<Products />} />
        <Route index element={<Navigate to="/product" />} />
        <Route path="*" element={<QNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
