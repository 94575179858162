import {
  AccessorFn,
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
  ColumnDef,
  ColumnMeta,
  DeepKeys,
  DeepValue,
  DisplayColumnDef,
  IdentifiedColumnDef,
  RowData,
} from '@tanstack/react-table';
import type { To } from 'react-router';

import { QTextCell } from './Cells/Text';
import { QTextLinkCell } from './Cells/TextLink';
import { QCodeCell } from './Cells/Code';
import { QDateCell } from './Cells/Date';
import { QTagCell } from './Cells/Tag';
import {
  HighlightPastDates,
  TagStatusMapping,
  TooltipAccessor,
} from './Cells/types';
import { QMenuCell } from './Cells/Menu';
import { QTextActionCell } from './Cells/TextAction';
import { QStatusCell } from './Cells/Status';
import { QIDCell } from './Cells/IDColumn';
import { RowPredicate } from './Cells/meta';
import { QIconCell } from './Cells/Icon';
import { IconNameType } from 'types/Icons';

export type QColumnWidth = `${number}%` | `${number}px`;

export type QColumnWidthDef = {
  width?: QColumnWidth;
  minWidth?: QColumnWidth;
  maxWidth?: QColumnWidth;
  /**
   * How much of the remaining space to allocate to this column
   * when width is unspecified.
   */
  weight?: number;
};

export type QColumnOptions<T> = {
  id?: string;
  header?: string;
  enableSorting?: boolean;
  /**
   * Controls how undefined values are sorted.
   * 'first' - Undefined values will be pushed to the beginning of the list
   *  'last' - (DEFAULT) Undefined values will be pushed to the end of the list
   *   false - Undefined values will be considered tied and need to be sorted by the next column filter or original index (whichever applies)
   *      -1 - Undefined values will be sorted with higher priority (ascending) (if ascending, undefined will appear on the beginning of the list)
   *       1 - Undefined values will be sorted with lower priority (descending) (if ascending, undefined will appear on the end of the list)
   */
  sortUndefined?: ColumnDef<T, unknown>['sortUndefined'];
  /** The data-cy attribute to apply to the cell.
   * If it's a string, it will be applied to each cell UNALTERED (cells will all have the same data-cy value).
   * If it's a function, it will be invoked for each row. */
  'data-cy'?: string | ((row: T) => string);
} & QColumnWidthDef;

export type QMenuColumnOptions<T extends RowData> = {
  items: React.ReactElement | React.ReactElement[];
  /** If this evaluates to true for a row, the menu button will not be shown. */
  hide?: RowPredicate<T>;
  'data-cy'?: string | ((row: T) => string);
};

export type QColumnHelper<TData extends RowData> = {
  /**
   * A plaintext cell: good for non-interactive text and numbers.
   *
   * **Don't use this for dates.**
   * Prefer using the `date` helper over formatting date objects yourself.
   */
  text: <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    column?: QColumnOptions<TData>,
  ) => ColumnDef<TData, TValue>;

  /**
   * **tl;dr** Use this to have a cell navigate when clicked.
   *
   * Creates a column with a link. If the `isExternal` option is set to true,
   * the link will open in a new tab, otherwise it will be navigated to by
   * the router.
   * @param accessor The accessor for the text to display in the link.
   * @param urlAccessor The accessor for the URL to navigate to.
   * @param isExternal If the link brings the user away from the Qualio app.
   * @param isCrossMFE If the link is intended to bring the user to another Qualio microfrontend.
   */
  textLink: <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    urlAccessor: AccessorFn<TData, To | null | undefined>,
    options?: QColumnOptions<TData> & {
      isExternal?: boolean;
      /**
       * @deprecated Use `isCrossMFE` instead.
       */
      reloadDocument?: boolean;
      isCrossMFE?: boolean;
      noOfLines?: number;
    },
  ) => ColumnDef<TData, TValue>;

  /**
   * **tl;dr** Use this to have a cell trigger a drawer or modal when clicked.
   *
   * Creates a column with an action. The action will be triggered when the
   * cell content is clicked.
   * @param accessor The accessor for the text to display in the cell.
   * @param onClick The action to trigger when the cell content is clicked.
   */
  textAction: <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    urlAccessor: AccessorFn<TData, void>,
    options?: QColumnOptions<TData>,
  ) => ColumnDef<TData, TValue>;

  /**
   * Displays a string (e.g. a code) in a badge.
   */
  code: <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    column?: QColumnOptions<TData>,
  ) => ColumnDef<TData, TValue>;

  /**
   * Displays the ID of the entity (e.g. Document ID POL-100).
   */
  id: <
    TAccessor extends AccessorFn<TData, string | number> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, string | number>
      ? string | number
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor> extends string | number
        ? string | number
        : never
      : never,
  >(
    accessor: TAccessor,
    column?: QColumnOptions<TData>,
  ) => ColumnDef<TData, TValue>;

  /**
   * Displays a date in a human-readable format.
   *
   * The displayed date is translated to be shown in the current user's
   * selected timezone (i.e. the timezone of their Qualio preferences!).
   */
  date: <
    TAccessor extends AccessorFn<TData, Date | undefined> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, Date | undefined>
      ? Date | undefined
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor> extends Date | undefined
        ? Date | undefined
        : never
      : never,
  >(
    accessor: TAccessor,
    options: QColumnOptions<TData> & {
      includeTime?: boolean;
      highlightPastDates?: HighlightPastDates<TData>;
    },
  ) => ColumnDef<TData, TValue>;

  /**
   * Displays a list of tags. Overflowing tags are shown as tooltips.
   * In the status mapping, you can use an asterisk `*` to specify
   * a fallback color for tags that don't match any other status.
   */
  tag: <
    TAccessor extends AccessorFn<TData, readonly string[]> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, readonly string[]>
      ? readonly string[]
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor> extends readonly string[]
        ? readonly string[]
        : never
      : never,
  >(
    accessor: TAccessor,
    options: Omit<QColumnOptions<TData>, 'maxWidth'> & {
      statuses: TagStatusMapping;
    },
  ) => ColumnDef<TData, TValue>;

  /**
   * Displays a single status (i.e. a tag).
   * In the status mapping, you can use an asterisk `*` to specify
   * a fallback color for statuses that don't match any other status.
   */
  status: <
    TAccessor extends AccessorFn<TData, string> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, string>
      ? string
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor> extends string
        ? string
        : never
      : never,
  >(
    accessor: TAccessor,
    options: QColumnOptions<TData> & {
      statuses: TagStatusMapping;
      tooltip?: TooltipAccessor<TData>;
    },
  ) => ColumnDef<TData, TValue>;

  icon: <
    TAccessor extends
      | AccessorFn<TData, IconNameType | undefined>
      | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, IconNameType | undefined>
      ? IconNameType | undefined
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor> extends string | undefined
        ? IconNameType | undefined
        : never
      : never,
  >(
    accessor: TAccessor,
    options: QColumnOptions<TData> & {
      tooltip?: TooltipAccessor<TData>;
      color?: string | AccessorFn<TData, string | undefined>;
    },
  ) => ColumnDef<TData, TValue>;

  menu: (options: QMenuColumnOptions<TData>) => ColumnDef<TData, unknown>;
};

export function createQColumnHelper<
  TData extends RowData,
>(): QColumnHelper<TData> {
  const make = <
    TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
    TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
      ? TReturn
      : TAccessor extends DeepKeys<TData>
      ? DeepValue<TData, TAccessor>
      : never,
  >(
    accessor: TAccessor,
    column: Omit<
      IdentifiedColumnDef<TData, TValue>,
      'accessorFn' | 'accessorKey'
    >,
  ) =>
    typeof accessor === 'function'
      ? ({
          sortUndefined: 'last',
          accessorFn: accessor,
          ...column,
        } as AccessorFnColumnDef<TData, TValue>)
      : ({
          sortUndefined: 'last',
          accessorKey: accessor,
          ...column,
        } as AccessorKeyColumnDef<TData, TValue>);

  return {
    text: (accessor, options) => {
      const { meta, ...column } = extractCommonMeta(options ?? {});
      return make(accessor, {
        ...column,
        meta,
        cell: QTextCell,
        sortingFn: 'alphanumeric',
      });
    },
    textLink: (labelAccessor, urlAccessor, options) => {
      const {
        meta,
        isExternal = false,
        reloadDocument = false,
        isCrossMFE = false,
        noOfLines = undefined,
        ...column
      } = extractCommonMeta(options ?? {});
      return make(labelAccessor, {
        ...column,
        meta: {
          ...meta,
          link: {
            urlAccessor,
            isExternal,
            isCrossMFE: isCrossMFE || reloadDocument,
            noOfLines,
          },
        },
        cell: QTextLinkCell,
        sortingFn: 'alphanumeric',
      });
    },
    textAction: (accessor, onClick, options) => {
      const { meta, ...column } = extractCommonMeta(options ?? {});
      return make(accessor, {
        ...column,
        meta: { ...meta, action: { onClick } },
        cell: QTextActionCell,
        sortingFn: 'alphanumeric',
      });
    },
    code: (accessor, options) => {
      const { meta, ...column } = extractCommonMeta(options ?? {});
      return make(accessor, {
        ...column,
        meta,
        cell: QCodeCell,
        sortingFn: 'alphanumeric',
      });
    },
    id: (accessor, options) => {
      const { meta, ...column } = extractCommonMeta({
        minWidth: '200px',
        ...options,
      });
      return make<
        AccessorFn<TData, string | number> | DeepKeys<TData>,
        string | number
      >(accessor, {
        ...column,
        meta,
        cell: QIDCell,
        sortingFn: 'alphanumeric',
      });
    },
    date: (accessor, options) => {
      const {
        includeTime = false,
        highlightPastDates = false,
        meta,
        ...column
      } = extractCommonMeta(options);
      return make<
        AccessorFn<TData, Date | undefined> | DeepKeys<TData>,
        Date | undefined
      >(accessor, {
        ...column,
        meta: { ...meta, date: { includeTime, highlightPastDates } },
        cell: QDateCell,
        sortingFn: 'datetime',
      });
    },
    tag: (accessor, options) => {
      const { statuses, meta, ...column } = extractCommonMeta({
        maxWidth: '200px',
        ...options,
      });
      return make<
        AccessorFn<TData, readonly string[]> | DeepKeys<TData>,
        readonly string[]
      >(accessor, {
        ...column,
        meta: { ...meta, tag: { statuses } },
        cell: QTagCell,
        sortingFn: 'alphanumeric',
      });
    },
    status: (accessor, options) => {
      const { statuses, tooltip, meta, ...column } = extractCommonMeta({
        maxWidth: '200px',
        ...options,
      });
      return make<AccessorFn<TData, string> | DeepKeys<TData>, string>(
        accessor,
        {
          ...column,
          meta: { ...meta, status: { statuses, tooltip } },
          cell: QStatusCell,
          sortingFn: 'alphanumeric',
        },
      );
    },
    menu: (options): DisplayColumnDef<TData, unknown> => {
      return {
        id: 'menu',
        cell: QMenuCell,
        meta: { menu: options, width: '56px' },
      };
    },
    icon: (accessor, options) => {
      const { tooltip, color, meta, ...column } = extractCommonMeta({
        maxWidth: '48px',
        ...(options ?? {}),
      });

      return make<
        AccessorFn<TData, IconNameType | undefined> | DeepKeys<TData>,
        IconNameType | undefined
      >(accessor, {
        ...column,
        meta: { ...meta, icon: { tooltip, color } },
        cell: QIconCell,
      });
    },
  };
}

type CommonMetaKeys =
  | keyof QColumnWidthDef
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Extract<keyof QColumnOptions<any>, 'data-cy'>;

type CommonMeta<T> = {
  meta: Pick<ColumnMeta<unknown, unknown>, CommonMetaKeys>;
} & Omit<T, CommonMetaKeys>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractCommonMeta = <T extends QColumnOptions<any>>(
  column: T,
): CommonMeta<T> => {
  const {
    width,
    minWidth,
    maxWidth,
    weight,
    'data-cy': dataCy,
    ...rest
  } = column;
  return {
    meta: { width, minWidth, maxWidth, weight, 'data-cy': dataCy },
    ...rest,
  };
};
