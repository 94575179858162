import React, { useCallback } from 'react';
import { RowData } from '@tanstack/react-table';
import { QMenuItem, QMenuItemProps } from '../../../QAtoms';
import { QBox } from '../../../QLayouts';
import { useTableMenuContext } from './context';

export type TableMenuItemProps<T extends RowData> = {
  onClick: (item: T) => void;
  isDisabled?: (item: T) => boolean;
  isHidden?: (item: T) => boolean;
} & Omit<QMenuItemProps, 'onClick' | 'isDisabled'>;

export function TableMenuItem<T>({
  onClick,
  isDisabled,
  isHidden,
  children,
  ...rest
}: TableMenuItemProps<T>): React.ReactElement {
  const { datum } = useTableMenuContext<T>();
  const onItemClick = useCallback(() => onClick(datum), [datum, onClick]);

  if (isHidden?.(datum)) {
    return <QBox data-cy="menu-item-hidden" />;
  }

  return (
    <QMenuItem {...rest} isDisabled={isDisabled?.(datum)} onClick={onItemClick}>
      {children}
    </QMenuItem>
  );
}
