import { useControllableState } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import {
  QTabList,
  QTabs,
  QTabsWithCompositeProps,
  QRouteTabProps,
} from '../../../QAtoms/Tabs/Tabs';
import { usePartitionedChildren } from '../../../utils';
import { SplitPanelLeft, SplitPanelLayoutProps } from '../SplitPanel';

export type SplitPanelWithRoutableTabsProps = Omit<
  SplitPanelLayoutProps,
  'children'
> &
  Pick<QTabsWithCompositeProps, 'onChange'> & {
    children: [React.ReactElement, ...React.ReactNode[]];
  };

/**
 * A SplitPanelLeft with a vertical QTabs component that is controlled by the router.
 * The children of this component are expected to be a QHeader followed by one or more QRouteTab components and a Routes component.
 * You are expected to provide a router (i.e. BrowserRouter, etc) as an ancestor of this component.
 *
 * You can also use QTab components instead, but you'll need to supply your own onClick handler to change the route.
 *
 * NOTE: If mixing QRouteTab and QTab components, the QTab components will be rendered first, followed by the QRouteTab components.
 *
 * @example
 * <QBodyLayout.SplitPanelLeftWithRoutableTabs>
 *   <MyHeaderComponent />
 *   <QRouteTab destination="/tab/foo">Foo</QRouteTab>
 *   <QRouteTab destination="/tab/bar">Bar</QRouteTab>
 *   <QTab onClick={navigateToSomewhere}>Qux</QTab>
 *   <Routes>
 *     <Route path="/tab/foo" element={<MyFooComponent />} />
 *     <Route path="/tab/bar" element={<MyBarComponent />} />
 *     <Route path="*" element={<MyElement />} />
 *   </Routes>
 * </QBodyLayout.SplitPanelLeftWithRoutableTabs>
 */
export const SplitPanelLeftWithRoutableTabs: React.VFC<
  SplitPanelWithRoutableTabsProps
> = ({ children: [header, ...children], size, ...tabsProps }) => {
  const {
    QTab: regularTabs,
    QRouteTab: routeTabs,
    QFooter: footers,
    unmatched,
  } = usePartitionedChildren(children, 'QTab', 'QRouteTab', 'QFooter');

  const [index, setIndex] = useControllableState({
    onChange: tabsProps.onChange,
    shouldUpdate: (prev, next) => prev !== next,
  });

  const { pathname } = useLocation();
  useEffect(() => {
    const index = routeTabs.findIndex((tab) =>
      isRouteTabWithMatchingDestination(tab, pathname),
    );
    if (index !== -1) {
      setIndex(index);
    }
  }, [pathname]);

  return (
    <QTabs
      orientation="vertical"
      {...tabsProps}
      index={index}
      onChange={setIndex}
    >
      <SplitPanelLeft size={size}>
        {header}
        <QTabList>
          {regularTabs}
          {routeTabs}
        </QTabList>
        <>
          {unmatched}
          <Outlet />
        </>

        {footers.at(index) ?? footers.at(0) ?? null}
      </SplitPanelLeft>
    </QTabs>
  );
};

const isRouteTabWithMatchingDestination = (
  element: React.ReactElement,
  pathname: string,
): boolean =>
  isQRouteTabProps(element.props) &&
  pathname.startsWith(element.props.destination);

const isQRouteTabProps = (props: unknown): props is QRouteTabProps =>
  typeof props === 'object' &&
  props !== null &&
  'destination' in props &&
  typeof props.destination === 'string';
