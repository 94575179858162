import React from "react";
import { useRequirementPolicy } from "../../../hooks/createProductSteps/useRequirementPolicy";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RequirementPolicies } from "../../../types/createProductStore";
import { RequirementPolicy } from "../../../components/RequirementPolicy/RequirementPolicy";

type Props = {
  nextRef: React.RefObject<any>;
};

export const Requirements: React.FC<Props> = ({ nextRef }) => {
  const { policies, storeRequirementPolicies } = useRequirementPolicy();
  const methods = useForm<RequirementPolicies>({
    defaultValues: policies,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RequirementPolicies),
  });

  return (
    <FormProvider {...methods}>
      <RequirementPolicy />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(storeRequirementPolicies)}
      />
    </FormProvider>
  );
};
