import React from 'react';
import { QFlex, QSpacer } from '../../../QLayouts';
import { ItemCount } from './ItemCount';
import { Pagination } from './Pagination';
import { usePaginationMaybe } from '../Pagination';

export type TableControlsProps = {
  /**
   * When true, the count of items in the table will be hidden.
   */
  hideItemCount?: boolean;
};

export const QTableControls: React.FC<TableControlsProps> = ({
  hideItemCount,
  children,
}) => {
  const pagination = usePaginationMaybe();
  const numChildren = React.Children.count(children);
  if (hideItemCount && !pagination && !numChildren) {
    // If we have nothing to show, we want to avoid a 52px whitespace gap.
    return null;
  }
  return (
    <QFlex direction="row" align="center" height="52px">
      {!hideItemCount && <ItemCount />}
      <QSpacer />
      {numChildren ? children : <Pagination />}
    </QFlex>
  );
};
