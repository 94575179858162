import React from 'react';
import { QIconButton } from '../../../QAtoms';
import { useQPropertiesPanelStateContext } from '../context';

/**
 * A button specially for openning the properties panel.
 * Hides itself when the properties panel is already open.
 */
export const QOpenPropertiesPanelButton: React.VFC = () => {
  const {
    disclosure: { isOpen, onOpen },
  } = useQPropertiesPanelStateContext();
  return isOpen ? null : (
    <QIconButton
      iconName="ChevronsLeft"
      aria-label="Open properties panel"
      onClick={onOpen}
    />
  );
};
