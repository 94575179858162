import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { useCurrentUser } from '../../../hooks';
import { QStack } from '../../../QLayouts';
import { QIconButton, QInput, QText } from '../../../QAtoms';
import { FilterModal } from '../Filtering/modal';
import { FilterDefinition, ResolvedFilter } from '../Filtering';
import { useFiltering } from '../Filtering';
import { FilterCustomView } from '../Filtering/custom';

export type FilterProps = {
  customFilter?: boolean;
};

export const Filters: React.FC<FilterProps> = ({
  customFilter = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { definitions } = useFiltering();

  // If there are available filters, build logic to display custom filter or filters in modal
  const filtersAvailable = Object.keys(definitions).length > 0;
  const showFilterButton = filtersAvailable && !customFilter;
  const showCustomFilter = filtersAvailable && customFilter;

  return (
    <>
      <QStack marginY="8px">
        <QStack direction="row">
          {showCustomFilter && <FilterCustomView>{children}</FilterCustomView>}
          {showFilterButton && <EditFiltersButton onClick={openModal} />}
          <SearchInput />
        </QStack>
        {showFilterButton && <ActiveFiltersView />}
      </QStack>
      {isOpen && (
        // isOpen is done this way so that the form state resets
        // properly when the modal closes.
        <FilterModal isOpen onClose={closeModal}>
          {children}
        </FilterModal>
      )}
    </>
  );
};

type EditFiltersButtonProps = {
  onClick: () => void;
};

const EditFiltersButton: React.VFC<EditFiltersButtonProps> = ({ onClick }) => {
  return (
    <QIconButton
      aria-label="Open filter modal"
      iconName="Filter"
      variant="outline"
      onClick={onClick}
    />
  );
};

const ActiveFiltersView: React.VFC = () => {
  const { definitions, filters, removeFilter, setFilters } = useFiltering();

  const items = useMemo((): React.ReactNode => {
    const removeArrayItem = (key: string, value: unknown) =>
      setFilters({
        [key]: (filters[key].value as unknown[]).filter((v) => v !== value),
      });

    return Object.entries(filters).map(([key, filter]) => {
      if (Array.isArray(filter.value)) {
        return filter.value.map(
          (value, i): React.ReactElement => (
            <WrapItem key={`${key}-${i}`}>
              <ActiveFilter
                filter={{ ...filter, value }}
                onRemove={() => removeArrayItem(key, value)}
                render={definitions[key]?.activeRender}
              />
            </WrapItem>
          ),
        );
      }
      return (
        <WrapItem key={key}>
          <ActiveFilter
            filter={filter}
            onRemove={() => removeFilter(key)}
            render={definitions[key]?.activeRender}
          />
        </WrapItem>
      );
    });
  }, [definitions, filters, removeFilter]);

  return (
    <Wrap direction="row" spacing="8px">
      {items}
    </Wrap>
  );
};

type ActiveFilterProps = {
  filter: ResolvedFilter<unknown>;
  onRemove: () => void;
  /** Optional: customise the display of the active filter */
  render?: FilterDefinition['activeRender'];
};

const ActiveFilter: React.VFC<ActiveFilterProps> = ({
  filter: { label, value },
  onRemove,
  render,
}) => {
  const { formatDate } = useCurrentUser();

  const custom = useMemo(() => {
    const r = render?.(value);
    if (!r) {
      return undefined;
    }

    if ((r as unknown)?.['label'] || (r as unknown)?.['value']) {
      return r as { label?: React.ReactNode; value?: React.ReactNode };
    }

    return {
      label: undefined,
      value: r as React.ReactNode,
    };
  }, [render, value]);

  return (
    <QStack
      direction="row"
      height="32px"
      spacing="8px"
      padding="4px"
      alignItems="center"
      border="1px solid"
      borderColor="blue.400"
      borderRadius="4px"
      backgroundColor="blue.50"
      fontSize="14px"
      data-cy={`active-filter-${label}`}
    >
      {custom?.label !== null && <QText>{custom?.label || `${label}:`}</QText>}
      <QText fontWeight={600}>
        {custom?.value ??
          (value instanceof Date ? formatDate(value) : `${value}`)}
      </QText>
      <QIconButton
        aria-label="Remove"
        iconName="X"
        onClick={onRemove}
        size="xs"
      />
    </QStack>
  );
};

const SearchInput: React.VFC = () => {
  const { searchTerm, setSearchTerm } = useFiltering();

  const onSearchTermChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearchTerm(e.target.value),
    [setSearchTerm],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        setSearchTerm(e.currentTarget.value, Date.now());
      }
    },
    [setSearchTerm],
  );

  return (
    <QInput
      iconLeftName="Search"
      placeholder="Search..."
      isClearable
      value={searchTerm}
      onChange={onSearchTermChange}
      onKeyDown={handleKeyDown}
      data-cy="table-search-input"
    />
  );
};
