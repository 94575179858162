import {
  TestCaseLevel1Policy,
  TestCaseLevel2Policy,
  TestCaseLevel3Policy,
} from "@design-controls/types";

type TestCaseConfiguration = {
  testCase1?: TestCaseLevel1Policy;
  testCase2?: TestCaseLevel2Policy;
  testCase3?: TestCaseLevel3Policy;
};

export const INITIAL_TESTCASE_CONFIGURATION: TestCaseConfiguration = {
  testCase1: {
    codePrefix: "VAL",
    label: "Validation",
    codeStrategy: "GENERATED",
    type: "testCase1",
  },
  testCase2: {
    codePrefix: "VER",
    label: "Verification",
    codeStrategy: "GENERATED",
    type: "testCase2",
  },
} as const;
