import { usePaginationMaybe } from '../../Pagination';
import { useDataProvider } from '../../DataProviders';

type DataCountResult = {
  count: number | undefined;
  isLoading: boolean;
};

export const useDataCount = (): DataCountResult => {
  const pagination = usePaginationMaybe();
  const { data = [], isLoading } = useDataProvider();

  const count = isLoading ? undefined : pagination?.itemCount ?? data.length;

  return { count, isLoading };
};
