import React, { useMemo } from "react";

import { isRequirementPolicy } from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  QButton,
  QCheckbox,
  QFormControl,
  QInput,
  QModal,
  QModalBody,
  QModalHeader,
  QSelect,
  QSpacer,
  QStack,
  QText,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { useTestCaseModal } from "../../hooks/useTestCaseModal";
import { CreateTestCaseForm } from "./types";

export const TestCaseModal: React.FC = () => {
  const { isOpen, closeModal } = useTestCaseModal();
  const configs = useCurrentConfigs();

  const methods = useForm<CreateTestCaseForm>({
    defaultValues: {
      type: "testCase1",
      title: "",
      addAnother: false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(CreateTestCaseForm),
  });

  const testCaseOptions = useMemo(() => {
    return configs.filter(isRequirementPolicy).map((item) => ({
      label: item.label,
      value: item.type,
    }));
  }, [configs]);

  return (
    <QModal isOpen={isOpen} onClose={closeModal} size="xl">
      <QModalHeader>
        <QText>Create test case</QText>
      </QModalHeader>
      <QModalBody>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(console.log)}>
            <QStack gap="4">
              <QFormControl label="Test" isRequired>
                <Controller
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <QSelect
                      options={testCaseOptions}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </QFormControl>

              <QFormControl label="Test case name" isRequired>
                <Controller
                  name="title"
                  render={({ field: { onChange, value } }) => (
                    <QInput onChange={onChange} value={value} />
                  )}
                />
              </QFormControl>

              <QStack justifyContent="space-between" direction="row">
                <Controller
                  name="addAnother"
                  render={({ field: { value, onChange } }) => (
                    <QCheckbox
                      size="sm"
                      data-cy="add-another-checkbox"
                      onChange={onChange}
                      value={value}
                    >
                      Add another
                    </QCheckbox>
                  )}
                />

                <QSpacer />
                <QStack direction="row">
                  <QButton
                    variant="outline"
                    data-cy="cancel-button"
                    onClick={closeModal}
                    isDisabled={false}
                  >
                    Cancel
                  </QButton>
                  <QButton
                    data-cy="submit-button"
                    type="submit"
                    isLoading={false}
                    isDisabled={false}
                  >
                    Create test case
                  </QButton>
                </QStack>
              </QStack>
            </QStack>
          </form>
        </FormProvider>
      </QModalBody>
    </QModal>
  );
};
