import React from "react";

import { QFormControl, QSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

export const Status: React.FC = () => {
  return (
    <Controller
      name="status"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Status">
          <QSelect
            value={value}
            onChange={(selectedItem) =>
              selectedItem && onChange(selectedItem.value)
            }
            options={[
              { value: "IN_PROGRESS", label: "In progress" },
              { value: "APPROVED", label: "Approved" },
              { value: "NOT_APPROVED", label: "Declined" },
            ]}
          />
        </QFormControl>
      )}
    />
  );
};
