import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";

import { designControlsApi } from "../api/designControls";
import { useCurrentProduct } from "./useCurrentProduct";

export const useRetrieveReviews = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: ["REVIEW_LIST", companyId, productId],
    queryFn: () => designControlsApi.retrieveReviews(companyId, productId),
  });
};
