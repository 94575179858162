import { Product } from "@design-controls/types";
import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useMutation } from "react-query";
import productApi from "../../api/product";

export const useDeleteProduct = (product: Product) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  return useMutation(
    (productId: string) => productApi.delete(companyId, productId),
    {
      onSuccess: () => {
        showToast({
          title: "Product deleted.",
          description: `You have deleted ${product.name}`,
          status: "success",
          duration: 5000,
        });
      },
      onError: () => {
        showToast({
          title: "Unable to delete product.",
          description: `Unable to delete ${product.name}`,
          status: "error",
          duration: 5000,
        });
      },
    },
  );
};
