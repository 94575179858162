import React from "react";

import {
  QButton,
  QCloseButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpinner,
  QualioQThemeProvider,
  QDeleteConfirmationModal,
  QText,
} from "@qualio/ui-components";

import itemsApi from "../../api/items";
import { BaseConfigurationType } from "../../types/baseConfiguration";
import {
  allRequirements,
  BaseQualityItem,
  DesignElementType,
} from "../../types/baseQualityItem";
import { RiskSubType } from "../../types/risk";
import { Message, MessageType } from "../Messages/types";

export type PolicyToBeDeleted = {
  policyType: DesignElementType;
  policy: BaseConfigurationType;
  noOfChildren?: number;
  isLoading: boolean;
};
export type Props = {
  onConfirm: () => void;
  onClose: () => void;
  policyToBeDeleted?: PolicyToBeDeleted;
};
export const DeleteDesignElementPolicyModal: React.FC<Props> = ({
  onConfirm,
  policyToBeDeleted,
  onClose,
}) => {
  const isRequirement =
    policyToBeDeleted &&
    allRequirements.some((reqType) => policyToBeDeleted.policyType === reqType);
  const isLoading = !policyToBeDeleted || policyToBeDeleted.isLoading;
  const isDeletable = policyToBeDeleted && policyToBeDeleted.noOfChildren === 0;

  const confirmPolicyDelete = () => {
    if (policyToBeDeleted) {
      onConfirm();
    }
  };

  const deletableBody = () => {
    return (
      <QText fontSize="sm">
        It won’t be possible to create design elements based on this policy
        anymore.
      </QText>
    );
  };
  const undeletableBody = () => {
    const designElementName =
      policyToBeDeleted?.policyType === DesignElementType.RISK
        ? "FMEA risks"
        : `"${policyToBeDeleted?.policy.prefix} - ${policyToBeDeleted?.policy.label}"`;
    return (
      <QText fontSize="sm">
        This policy is still used in the product. To
        {isRequirement ? "delete" : "disable"} it here, first delete this
        product’s {designElementName}
      </QText>
    );
  };
  const buildModalBody = () => (
    <QModalBody data-cy="delete-modal-content">
      {policyToBeDeleted && policyToBeDeleted.isLoading ? (
        <QSpinner />
      ) : isDeletable ? (
        deletableBody()
      ) : (
        undeletableBody()
      )}
    </QModalBody>
  );

  const buildModalButtons = () => {
    if (isLoading) {
      return <></>;
    }
    return (
      <>
        {!isDeletable && (
          <QButton onClick={onClose} data-cy={"delete-modal-close-button"}>
            Ok
          </QButton>
        )}
      </>
    );
  };

  const buildHeaderText = () => {
    const deleteDisableText = isRequirement
      ? "Delete policy?"
      : "Disable policy?";
    return (
      <QText>
        {isDeletable || isLoading ? deleteDisableText : "Policy in use"}
      </QText>
    );
  };

  const buildSpinner = () => {
    return (
      <QModal isOpen={!!policyToBeDeleted} onClose={onClose}>
        <QModalBody data-cy="delete-modal-content">
          <QSpinner />
        </QModalBody>
      </QModal>
    );
  };

  return (
    <QualioQThemeProvider>
      {!!isLoading ? (
        buildSpinner()
      ) : isDeletable ? (
        policyToBeDeleted && (
          <QDeleteConfirmationModal
            onConfirm={confirmPolicyDelete}
            onClose={onClose}
            title={"Delete policy?"}
            message={deletableBody()}
          />
        )
      ) : (
        <QModal isOpen={!!policyToBeDeleted} onClose={onClose}>
          <QModalHeader>
            <span>{buildHeaderText()}</span>
            <QCloseButton onClick={onClose} />
          </QModalHeader>
          {buildModalBody()}
          <QModalActions data-cy="delete-modal-actions">
            {buildModalButtons()}
          </QModalActions>
        </QModal>
      )}
    </QualioQThemeProvider>
  );
};

export const triggerPolicyDeletion = (
  companyId: number,
  productId: string,
  policyType: DesignElementType,
  policy: BaseConfigurationType,
  setPolicyToBeDeleted: (
    policyToBeDeleted: PolicyToBeDeleted | undefined,
  ) => void,
  clearMessages: () => void,
  addMessage: (message: Message) => void,
  subType?: RiskSubType,
): Promise<void> => {
  clearMessages();
  setPolicyToBeDeleted({
    policy: policy,
    isLoading: true,
    policyType: policyType,
  });
  return itemsApi
    .query<BaseQualityItem>(companyId, productId, policyType, subType)
    .then((designElements) => {
      setPolicyToBeDeleted({
        policy: policy,
        policyType: policyType,
        isLoading: false,
        noOfChildren: designElements.length,
      });
    })
    .catch(() => {
      addMessage({
        type: MessageType.DANGER,
        title: `Failed to delete policy configuration`,
        text: "Please try again",
      });
      setPolicyToBeDeleted(undefined);
    });
};
