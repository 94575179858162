import React from 'react';

import { chakra, Tooltip, TooltipProps } from '@chakra-ui/react';
import { usePartitionedChildren } from '../../utils';

export type QTooltipProps = Required<Pick<TooltipProps, 'label'>> &
  Pick<TooltipProps, 'placement' | 'openDelay'>;

/**
 * Shows a tooltip when hovering over the children.
 *
 * To work correctly, the children must accept a `ref`.
 * See the Storybook for an example.
 *
 * @example
 * <QTooltip label="This is a tooltip">Hover me</QTooltip>
 */
export const QTooltip: React.FC<QTooltipProps> = ({
  label,
  placement = 'bottom',
  openDelay,
  children,
}) => {
  const { QIcon, unmatched } = usePartitionedChildren(children, 'QIcon');
  if (QIcon.length) {
    if (unmatched.length) {
      console.warn(
        'Developer error: QTooltip given a mix of QIcon and other children.',
      );
    }
    return (
      <Tooltip label={label} placement={placement} openDelay={openDelay}>
        <chakra.span
          display="inline-flex"
          verticalAlign="baseline"
          alignItems="center"
        >
          {children}
        </chakra.span>
      </Tooltip>
    );
  }
  return (
    <Tooltip label={label} placement={placement} openDelay={openDelay}>
      {children}
    </Tooltip>
  );
};
