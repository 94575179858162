import React, { useMemo } from "react";

import { ReviewList as ReviewListType } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QDataTable,
} from "@qualio/ui-components";

import { useRetrieveReviews } from "../../hooks/useRetrieveReviews";
import { filterDefinitions } from "./filter/definition";
import { Owner } from "./filter/Owner";
import { Status } from "./filter/Status";

const columnHelper = createQColumnHelper<ReviewListType>();

const StatusTable: Record<string, string> = {
  IN_PROGRESS: "In progress",
  APPROVED: "Approved",
  NOT_APPROVED: "Declined",
};

const filterOption = ((
  item: Readonly<ReviewListType>,
  searchTerm: string | undefined,
  filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
) => {
  if (
    searchTerm &&
    !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  ) {
    return false;
  }

  if (filters && Object.values(filters).some((field) => field.value)) {
    const { owner, status } = filters;

    return (
      (!owner?.value || item.createdBy.id === owner.value) &&
      (!status?.value || item.status === status.value)
    );
  }

  return true;
}) as DataProvider.FilterOptionFn<ReviewListType>;

export const ReviewList: React.FC = () => {
  const { isLoading, data } = useRetrieveReviews();

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => record.id, {
        header: "Review name",
        weight: 1,
      }),
      columnHelper.text((record) => record.createdBy.fullName, {
        header: "Owner",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        maxWidth: "150px",
      }),
      columnHelper.status((record) => StatusTable[record.status], {
        header: "Status",
        maxWidth: "150px",
        statuses: {
          "In progress": "gray",
          Approved: "green",
          Declined: "red",
        },
      }),
    ];
  }, []);

  return (
    <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
      <Filtering.FilterProvider
        definitions={filterDefinitions}
        searchTermKey="search"
      >
        <DataProvider.Fixed
          data={data ?? []}
          isLoading={isLoading}
          filterOption={filterOption}
        >
          <QDataTable
            columns={columns}
            data-cy="review-list"
            getRowId={(row) => row.id}
          >
            <Filtering.FormContent>
              <Owner />
              <Status />
            </Filtering.FormContent>
          </QDataTable>
        </DataProvider.Fixed>
      </Filtering.FilterProvider>
    </Pagination.Auto>
  );
};
