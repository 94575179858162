import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const DropDown = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 20 12" color="currentColor" {...props} ref={ref}>
        <svg width="20" height="12" fill="none">
          <g xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
            <rect height="11" rx="1.5" width="19" x=".5" y=".5" />
            <path
              d="m13 5 2 2 2-2"
              strokeLinecap="round"
              fill="currentColor"
              color="currentColor"
            />
          </g>
        </svg>
      </Icon>
    );
  },
);

DropDown.displayName = 'DropDown';
