import React, { useEffect } from 'react';
import { QBox } from '../../QLayouts';
import { QCollapseHorizontal } from '../../QAtoms';
import { useQPropertiesPanelStateContext } from './context';
import { QPropertiesPanelPortal } from './outlet';

export type QPropertiesPanelProps = {
  openOnMount?: boolean;
};

/**
 * A sliding panel intended to display properties of the currently
 * selected Qualio object.
 *
 * Accepts arbitrary React children.
 *
 * Portals its content to the nearest `QPropertiesPanelOutlet`,
 * which is usually the `QBodyLayout`.
 */
export const QPropertiesPanel: React.FC<QPropertiesPanelProps> = ({
  openOnMount,
  children,
}) => {
  const {
    setAvailable,
    disclosure: { isOpen, onOpen },
  } = useQPropertiesPanelStateContext();

  useEffect(() => {
    setAvailable(true);
    return () => {
      setAvailable(false);
    };
  }, [setAvailable]);

  useEffect(() => {
    if (openOnMount) {
      onOpen();
    }
  }, []);
  return (
    <QPropertiesPanelPortal>
      <QCollapseHorizontal isOpen={isOpen}>
        <QBox
          height="100%"
          paddingLeft="24px"
          marginBottom="24px"
          borderLeft="1px"
          borderColor="gray.200"
          marginLeft="24px"
        >
          {children}
        </QBox>
      </QCollapseHorizontal>
    </QPropertiesPanelPortal>
  );
};
