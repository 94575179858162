import {
  RequirementLevel1Policy,
  RequirementLevel2Policy,
  RequirementLevel3Policy,
  RequirementLevel4Policy,
} from "@design-controls/types";

type RequirementConfiguration = {
  req1: RequirementLevel1Policy;
  req2: RequirementLevel2Policy;
  req3: RequirementLevel3Policy;
  req4: RequirementLevel4Policy;
};

export const INITIAL_REQUIREMENT_CONFIGURATION: RequirementConfiguration = {
  req1: {
    codePrefix: "UN",
    label: "User Need",
    codeStrategy: "GENERATED",
    type: "req1",
  },
  req2: {
    codePrefix: "DI",
    label: "Design Input",
    codeStrategy: "GENERATED",
    type: "req2",
  },
  req3: {
    codePrefix: "DO",
    label: "Design Output",
    codeStrategy: "GENERATED",
    type: "req3",
  },
  req4: {
    codePrefix: "",
    label: "",
    codeStrategy: "GENERATED",
    type: "req4",
  },
} as const;
