import { BaseQualityType } from '../../types/baseQualityItem';
import { OpenIssue } from '../../types/openIssue';
import { Payload } from '../../types/payload';
import { makeResponse } from './generic';
import { req1QualityItems, testCaseQualityItems } from './qualityItems';

export const req1Issues: OpenIssue[] = [
  {
    ...req1QualityItems[0],
    policyIssues: {
      REQUIRES_PARENT: false,
      UNMITIGATED: false,
      REQUIRES_CHILD: true,
      UNTESTED_REQUIREMENT: true,
      FAILING_TEST: false,
      OUTDATED_TESTRESULT: false,
    },
  },
  {
    ...req1QualityItems[1],
    policyIssues: {
      REQUIRES_PARENT: false,
      UNMITIGATED: false,
      REQUIRES_CHILD: false,
      UNTESTED_REQUIREMENT: false,
      FAILING_TEST: false,
      OUTDATED_TESTRESULT: false,
    },
  },
];

export const testCaseIssues: OpenIssue[] = [
  {
    ...testCaseQualityItems[0],
    policyIssues: {
      REQUIRES_PARENT: true,
      UNMITIGATED: false,
      REQUIRES_CHILD: false,
      UNTESTED_REQUIREMENT: false,
      FAILING_TEST: true,
      OUTDATED_TESTRESULT: true,
    },
  },
];

export const allOpenIssuesResponse: Payload<OpenIssue[]> = makeResponse([...req1Issues, ...testCaseIssues]);

export const openIssuesByTypeResponse: Record<BaseQualityType, Payload<OpenIssue[]>> = {
  req1: makeResponse(req1Issues),
  testCase1: makeResponse([]),
  req2: makeResponse([]),
  testCase2: makeResponse([]),
  req3: makeResponse([]),
  req4: makeResponse([]),
  testCase3: makeResponse([]),
  testLog: makeResponse([]),
  testCase: makeResponse(testCaseIssues),
  document: makeResponse([]),
  risk: makeResponse([]),
};
