import React, { useMemo } from "react";

import { isRequirementPolicy } from "@design-controls/types";
import { QFormControl, QSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";

export const RequirementType: React.FC = () => {
  const configs = useCurrentConfigs();

  const options = useMemo(() => {
    return configs
      .filter(isRequirementPolicy)
      .map((config) => ({ label: config.label, value: config.type }));
  }, [configs]);

  return (
    <Controller
      name="type"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Requirement type">
          <QSelect
            value={value}
            onChange={(selectedItem) =>
              selectedItem && onChange(selectedItem.value)
            }
            options={options}
          />
        </QFormControl>
      )}
    />
  );
};
