import React from 'react';
import { useStyleConfig } from '@chakra-ui/react';
import { QPropertiesPanelOutlet } from '../../QOrganisms/PropertiesPanel/outlet';
import { QPropertiesPanelStateProvider } from '../../QOrganisms/PropertiesPanel/context';
import { QFlex } from '..';

export type DefaultLayoutProps = {
  children: [React.ReactElement, ...(React.ReactElement | null)[]];
  size?: 'sm' | 'md' | 'lg';
};

export const Default: React.VFC<DefaultLayoutProps> = ({
  children: [header, ...content],
  size,
}) => {
  const styles = useStyleConfig('BodyLayout', { size });
  const maxWidth = size ? `${styles?.maxWidth}` : undefined;
  return (
    <QFlex
      direction="column"
      alignItems="center"
      maxWidth={maxWidth}
      width="100%"
      mx="auto"
      flex="1"
    >
      <QPropertiesPanelStateProvider>
        <QFlex direction="row" flexGrow={1} w="100%">
          <QFlex direction="column" h="100%" w="100%" alignItems="stretch">
            {header}
            <QFlex direction="column" flex="1" paddingTop="24px">
              {content}
            </QFlex>
          </QFlex>
          <QPropertiesPanelOutlet />
        </QFlex>
      </QPropertiesPanelStateProvider>
    </QFlex>
  );
};
