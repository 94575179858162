import { Filtering } from "@qualio/ui-components";

export const FILTER_DEFINITIONS = {
  tag_ids: {
    label: "Tags",
    schema: Filtering.schemas
      .StringSchema()
      .transform((s) => s?.split(",").map(Number)),
  },
} as const;
