import React, { useState } from "react";

import { Product, Policy, TestCaseDesignElement } from "@design-controls/types";
import { QPageLoader } from "@qualio/ui-components";
import { Navigate } from "react-router";
import { createStore, StoreApi } from "zustand";

import { useSelectedProduct } from "../hooks/useSelectedProduct";

export type ProductContext = {
  product: Product;
  policy: Policy[];
  requirementModal: boolean;
  testCaseModal: boolean;
  riskModal: boolean;
  testResultModal?: TestCaseDesignElement;
  openRequirementModal: () => void;
  closeRequirementModal: () => void;
  openTestCaseModal: () => void;
  closeTestCaseModal: () => void;
  openRiskModal: () => void;
  closeRiskModal: () => void;
};

export const CurrentProductContext =
  React.createContext<StoreApi<ProductContext> | null>(null);

export const CurrentProductProvider: React.FC = ({ children }) => {
  const { isLoading, product, configs } = useSelectedProduct();

  if (!isLoading && !product) {
    return <Navigate to="/" />;
  }

  if (isLoading || !product || !configs) {
    return <QPageLoader />;
  }

  return (
    <ProductProviderContainer policy={configs} product={product}>
      {children}
    </ProductProviderContainer>
  );
};

type Props = {
  product: Product;
  policy: Policy[];
};

const ProductProviderContainer: React.FC<Props> = ({
  policy,
  product,
  children,
}) => {
  const [store] = useState(() =>
    createStore<ProductContext>((set) => ({
      product,
      policy,
      requirementModal: false,
      testCaseModal: false,
      riskModal: false,
      openRequirementModal: () => set({ requirementModal: true }),
      closeRequirementModal: () => set({ requirementModal: false }),
      openTestCaseModal: () => set({ testCaseModal: true }),
      closeTestCaseModal: () => set({ testCaseModal: false }),
      openRiskModal: () => set({ riskModal: true }),
      closeRiskModal: () => set({ riskModal: false }),
    })),
  );

  return (
    <CurrentProductContext.Provider value={store}>
      {children}
    </CurrentProductContext.Provider>
  );
};
