export const FileSizeInBytes = {
  byte: (size: number) => size,
  kb: (size: number) => size * FileSizeInBytes.byte(1024),
  mb: (size: number) => size * FileSizeInBytes.kb(1024),
  gb: (size: number) => size * FileSizeInBytes.mb(1024),
} as const;

const Units = ['B', 'KB', 'MB', 'GB'] as const;

export const filesizeToUnitSize = (size: number): string => {
  let index = 0;
  let current = size;
  while (current >= 1024 && index < Units.length - 1) {
    current = current / 1024;
    index++;
  }
  return `${Math.floor(current)} ${Units[index]}`;
};

export type QFileInputValue = {
  id: string;
  filename: string;
  filesize: number;
};

export type FileListItem = QFileInputValue & { errorMessage?: string };
