import React from 'react';
import { QText } from '../../../QAtoms';
import { usePaginationMaybe } from '../Pagination/context';
import { useDataCount } from './hooks/useDataCount';

const FormatNumber = new Intl.NumberFormat();

export const ItemCount: React.VFC = () => {
  const pagination = usePaginationMaybe();
  const { count, isLoading } = useDataCount();

  if (isLoading) {
    return null;
  }

  // If we have pagination context, use the original pagination logic
  if (pagination?.itemCount) {
    const { itemCount, pageCount, pageSize } = pagination;
    const shouldShowExactCount =
      pageCount || (itemCount ?? Infinity) < pageSize;

    if (shouldShowExactCount) {
      return (
        <QText fontSize="sm" fontWeight={600}>
          {FormatNumber.format(itemCount)} {itemCount === 1 ? 'item' : 'items'}
        </QText>
      );
    }

    return itemCount ? (
      <QText fontSize="sm" fontWeight={600}>
        {FormatNumber.format(
          Math.floor(itemCount / (itemCount > 200 ? 100 : 10)) * 10,
        )}
        + items
      </QText>
    ) : null;
  }

  // For non-paginated cases, always show exact count
  if (count) {
    return (
      <QText fontSize="sm" fontWeight={600}>
        {FormatNumber.format(count)} {count === 1 ? 'item' : 'items'}
      </QText>
    );
  }

  return null;
};
