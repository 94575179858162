import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Product } from '@design-controls/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { QBox, QButton, QDivider, QFlex, QFormControl, QInput, QStack } from '@qualio/ui-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Outlet, useNavigate } from 'react-router-dom';

import qualioApi from '../../../api/qualio';
import { Editor } from '../../../components';
import { AsyncSearchAndSelectElement } from '../../../components_new/AsyncSearchAndSelectElements/AsyncSearchAndSelectElement';
import { useDrawerProvider } from '../../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider';
import { mapDesignElementToSelectedElementType } from '../../../components_new/DesignElementDisplay/lib/mapDesignElementToSelectedElementType';
import DesignElementGroup from '../../../components_new/DesignElementGroup/DesignElementGroup';
import {
  SearchAndSelectElement,
  SearchAndSelectElementType,
} from '../../../components_new/SearchAndSelectElements/SearchAndSelectElement';
import { SearchAndSelectElementApproverOption } from '../../../components_new/SearchAndSelectElements/SearchAndSelectElementApproverOption';
import { useCompanyId } from '../../../context/CurrentUserContext';
import { sortByTitle } from '../../../lib/sortFunctions';
import {
  ChangeControlApprover,
  ChangeControlApproverPayloadType,
  ChangeControlPayload,
  ChangeControlPayloadType,
  ChangeControlQualityItem,
  DashboardItem,
  SmartlinkPayloadType,
} from '../../../types/changeControl';
import { getLatestVersion } from '../lib/getLatestVersion';
import { documentsStatusLabelAndColor } from '../List/statusLabelAndColorTag';

export type ReviewFormProps = {
  product: Product;
  configs: Record<string, string>;
  availableQualityItems: Array<ChangeControlQualityItem | DashboardItem>;
  approvers: Array<ChangeControlApprover>;
  onSubmitForm: (reviewPayload: ChangeControlPayloadType) => Promise<void>;
  initialValues: ChangeControlPayloadType;
  isApproversLoading: boolean;
  onDeleteReview?: () => Promise<void>;
};

export const ReviewForm: React.FC<ReviewFormProps> = ({
  product,
  configs,
  availableQualityItems,
  approvers,
  onSubmitForm,
  initialValues,
  isApproversLoading,
  onDeleteReview,
}) => {
  const navigate = useNavigate();
  const company = useCompanyId();
  const [qualityItemOptions, setQualityItemOptions] = useState(availableQualityItems);
  const [isUpdatingDesignElement, setIsUpdatingDesignElement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm<ChangeControlPayloadType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(ChangeControlPayload),
  });
  const {
    formState: { errors },
  } = methods;

  const goToReviewList = useCallback(() => navigate(`/product/${product.id}/change-control`), [navigate, product.id]);

  const onSubmit = async (reviewPayload: ChangeControlPayloadType) => {
    setIsSubmitting(true);
    onSubmitForm(reviewPayload).finally(() => setIsSubmitting(false));
  };

  const onDelete = async () => {
    onDeleteReview && onDeleteReview();
  };

  const toApproverOption = (item: ChangeControlApproverPayloadType): SearchAndSelectElementType => ({
    id: item.id,
    label: item.fullName,
    title: item.fullName,
    item,
  });
  const approversOptions: Array<SearchAndSelectElementType> = approvers.map(toApproverOption);

  const toLinkedDocumentOption = (doc: SmartlinkPayloadType): SearchAndSelectElementType => ({
    id: doc.id,
    label: `${doc.code} - ${doc.title}`,
    title: doc.code ?? '',
    showTitleInBadge: true,
    selectedItemBody: doc.title,
    selectedItemLeftStatus: documentsStatusLabelAndColor(doc.status_id),
    item: doc,
  });
  const searchDocuments = useCallback(
    (search: string): Promise<Array<SearchAndSelectElementType>> => {
      if (search.length >= 3) {
        return qualioApi.searchDocuments(company, search).then((docs) => docs.map(toLinkedDocumentOption));
      } else {
        return Promise.resolve([]);
      }
    },
    [company],
  );
  const updateDesignElementToLatestVersion = useCallback(
    async (oldDesignElement: SearchAndSelectElementType) => {
      setIsUpdatingDesignElement(true);
      const latestVersionItem = await getLatestVersion(
        company,
        product.id,
        oldDesignElement.id,
        oldDesignElement.item.latest,
      );
      if (latestVersionItem) {
        setQualityItemOptions([
          ...qualityItemOptions.filter((item) => item.id !== oldDesignElement.id),
          latestVersionItem,
        ]);
      }
      setIsUpdatingDesignElement(false);
    },
    [company, product, qualityItemOptions],
  );

  const {
    handleItemSelected: handleDesignElementSelected,
    setSelectedDesignElement,
    selectedDesignElement,
  } = useDrawerProvider();

  const handleSelectedElementOnClick = useCallback(
    (designElementId: string, designElementVersion?: string) => {
      setSelectedDesignElement({ id: designElementId, version: designElementVersion });
      handleDesignElementSelected(designElementId, selectedDesignElement);
    },
    [handleDesignElementSelected, setSelectedDesignElement, selectedDesignElement],
  );

  // This is a (hacky) workaround that somewhow prevents the error that is otherwise thrown
  // when the editor component mounts. The issue only existed on this page.
  const [, setFoo] = useState(false);
  const renderEditorRef = useRef<(onChange: any, value: any) => JSX.Element>();
  useEffect(() => {
    renderEditorRef.current = (onChange, value) => <Editor onChange={onChange} value={value} />;
    setFoo(true);
    return () => {
      setFoo(false);
      renderEditorRef.current = undefined;
    };
  }, []);

  return (
    <>
      <QBox margin="auto" data-cy="change-control-form">
        <FormProvider {...methods}>
          <QFormControl label="Title" isInvalid={!!errors.title} error={errors.title?.message}>
            <Controller
              name={'title'}
              render={({ field: { onChange, value } }) => (
                <QInput onChange={onChange} value={value} data-cy="title-input" />
              )}
            />
          </QFormControl>
          <QFormControl
            mt={2}
            label="Description"
            isInvalid={!!errors.description}
            error={errors.description?.message}
          >
            <Controller
              name="description"
              rules={{ required: 'Required', maxLength: { value: 150_000, message: 'Description is too long' } }}
              render={({ field: { onChange, value } }) => (
                <div data-cy="descriptionWrapper">{renderEditorRef.current?.(onChange, value)}</div>
              )}
            />
          </QFormControl>
          <QDivider />
          <QStack spacing="30px" mt="30px" mb="30px">
            <QFormControl
              mt={2}
              isInvalid={!!errors.qualityItems}
              error={(errors.qualityItems as any)?.message}
            >
              <Controller
                name={'qualityItems'}
                render={({ field: { onChange, value } }) => (
                  <SearchAndSelectElement
                    title={'Design Elements under review'}
                    name={'qualityItems'}
                    placeHolderText={'Search design elements to add'}
                    onChange={(values) => onChange(values.map((value) => value.item))}
                    handleSelectedElementOnClick={handleSelectedElementOnClick}
                    value={mapDesignElementToSelectedElementType(value ?? [], configs, true)}
                    data={mapDesignElementToSelectedElementType(qualityItemOptions, configs, true)}
                    sorter={sortByTitle}
                    selectedOptionGroupComponent={DesignElementGroup}
                    updateDesignElementOnRemoval={updateDesignElementToLatestVersion}
                    isLoading={isUpdatingDesignElement}
                  />
                )}
              />
            </QFormControl>
            <QDivider />
            <Controller
              name={'approvers'}
              defaultValue={initialValues.approvers?.map(toApproverOption)}
              render={({ field: { onChange, value } }) => (
                <SearchAndSelectElement
                  title={'Approvers'}
                  name={'approvers'}
                  placeHolderText={'Search people to add'}
                  onChange={(values) => onChange(values.map((value) => value.item))}
                  value={value.map(toApproverOption)}
                  data={approversOptions}
                  sorter={sortByTitle}
                  isLoading={isApproversLoading}
                  selectedOptionComponent={SearchAndSelectElementApproverOption}
                />
              )}
            />
            <QDivider />
            <Controller
              name={'linkedDocuments'}
              defaultValue={initialValues.linkedDocuments?.map(toLinkedDocumentOption)}
              render={({ field: { onChange, value } }) => (
                <AsyncSearchAndSelectElement
                  title="Linked Documents"
                  name={'linkedDocuments'}
                  placeHolderText={'Search for documents (3 characters or more)'}
                  onChange={(values) => onChange(values.map((value) => value.item))}
                  value={value.map(toLinkedDocumentOption)}
                  loadOptions={searchDocuments}
                  sorter={sortByTitle}
                />
              )}
            />
          </QStack>
          <QDivider />
          <QFlex mt="30px" direction="row" justifyContent="space-between">
            {onDeleteReview ? (
              <QButton
                variant={'solid'}
                isDestructive={true}
                data-cy="change-control-form-delete-button"
                onClick={onDelete}
                isDisabled={isSubmitting}
              >
                Delete
              </QButton>
            ) : (
              <>&nbsp;</>
            )}
            <QStack spacing="20px" direction="row" justifyContent="flex-end">
              <QButton
                variant={'link'}
                onClick={goToReviewList}
                data-cy="change-control-form-cancel-button"
                isDisabled={isSubmitting}
              >
                Cancel
              </QButton>
              <QButton
                variant={'solid'}
                data-cy="change-control-form-submit-button"
                onClick={methods.handleSubmit(onSubmit)}
                isLoading={isSubmitting}
              >
                Save
              </QButton>
            </QStack>
          </QFlex>
        </FormProvider>
      </QBox>
      <Outlet />
    </>
  );
};
