import {
  QDeleteConfirmationModal,
  QMenuButton,
  QMenuItem,
  QText,
} from "@qualio/ui-components";
import React, { useState } from "react";
import { useDeleteProduct } from "../../hooks/useDeleteProduct";
import { Product } from "@design-controls/types";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import useHasPermission from "../../hooks/useHasPermission";
import { UserPermissions } from "../../../components";
import { useNavigate } from "react-router";

export const ManageProduct: React.FC = () => {
  const product = useCurrentProduct();
  const permissions = useHasPermission([UserPermissions.MANAGE_PRODUCT]);
  const navigate = useNavigate();

  const { isLoading: isDeleting, mutate: deleteProduct } =
    useDeleteProduct(product);

  const [productToDelete, setProductToBeDelete] = useState<Product | undefined>(
    undefined,
  );

  if (!permissions[UserPermissions.MANAGE_PRODUCT]) {
    return null;
  }

  return (
    <>
      <QMenuButton iconName="MoreVertical" variant="icon" data-cy="more-option">
        <QMenuItem>Configure product</QMenuItem>
        <QMenuItem
          color="red.500"
          data-cy="delete-this-product"
          onClick={() => setProductToBeDelete(product)}
        >
          Delete this product
        </QMenuItem>
      </QMenuButton>
      <>
        {productToDelete && (
          <QDeleteConfirmationModal
            inProgress={isDeleting}
            title="Delete product"
            message={
              <QText fontSize="sm">
                Are you sure you want to delete {product?.name}? You cannot undo
                this action.
              </QText>
            }
            onConfirm={() => {
              productToDelete &&
                deleteProduct(product.id, {
                  onSuccess: () => {
                    setProductToBeDelete(undefined);
                    navigate("/product");
                  },
                });
            }}
            onClose={() => setProductToBeDelete(undefined)}
          />
        )}
      </>
    </>
  );
};
