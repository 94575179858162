import { Filtering } from "@qualio/ui-components";

export const filterDefinitions = {
  type: {
    label: "Requirement type",
    schema: Filtering.schemas.StringSchema(),
  },
  component: {
    label: "Component",
    schema: Filtering.schemas.StringSchema(),
  },
  category: {
    label: "Category",
    schema: Filtering.schemas.StringSchema(),
  },
  show_only_open: {
    label: "Show open issues only",
    schema: Filtering.schemas.BooleanSchema(),
  },
} as const;
