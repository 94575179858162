import { QTag, QTagProps } from "@qualio/ui-components";
import React from "react";
import { ElementReviewStatus } from "../../constants/reviewStatus";
import { ChangeControlStatus } from "@design-controls/types";

type Props = {
  status: ChangeControlStatus;
};

export const ReviewStatusTag: React.FC<Props> = ({ status }) => {
  const variantColor: Record<ChangeControlStatus, QTagProps["variantColor"]> = {
    [ChangeControlStatus.enum.NOT_IN_REVIEW]: "gray",
    [ChangeControlStatus.enum.IN_PROGRESS]: "blue",
    [ChangeControlStatus.enum.APPROVED]: "green",
    [ChangeControlStatus.enum.NOT_APPROVED]: "red",
  };

  return (
    <QTag variantColor={variantColor[status]}>
      {ElementReviewStatus[status]}
    </QTag>
  );
};
