export const BodyLayout = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  sizes: {
    sm: {
      maxWidth: '720px',
    },
    md: {
      maxWidth: '1024px',
    },
    lg: {
      maxWidth: '1280px',
    },
  },
  defaultProps: {
    size: undefined,
  },
};
