import React, { useMemo, useState } from "react";

import { FMEARiskList as FMEARiskListType } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QCenter,
  QDataTable,
  QDeleteConfirmationModal,
  QIcon,
  QText,
  TableMenuItem,
} from "@qualio/ui-components";
import { Outlet } from "react-router";

import { useDeleteRisk } from "../../../hooks/designElement/useDeleteRisk";
import { useFMEARiskList } from "../../../hooks/useFMEARiskList";

const columnHelper = createQColumnHelper<FMEARiskListType>();

export const FMEARiskList = () => {
  const { isLoading, data } = useFMEARiskList();
  const [itemToDelete, setItemToDelete] = useState<FMEARiskListType | null>(
    null,
  );
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteRisk();

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => record.id, {
        header: "Risk name",
        weight: 1,
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        maxWidth: "150px",
      }),
      {
        header: "Issue",
        meta: {
          width: "70px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: FMEARiskListType };
        }) => {
          const missing = Object.entries(row.policyIssues).filter(
            ([, value]) => !!value,
          );

          if (!missing.length) {
            return "";
          }

          return (
            <QCenter>
              <QIcon
                iconName="FillExclamationTriangleFill"
                color="orange.500"
              />
            </QCenter>
          );
        },
      },
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={console.log} data-cy="edit">
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: FMEARiskListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== "product-development",
      }),
    ];
  }, []);

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Filtering.FilterProvider definitions={{}} searchTermKey="search">
          <DataProvider.Fixed
            data={data ?? []}
            isLoading={isLoading}
            filterOption={() => true}
          >
            <QDataTable
              columns={columns}
              data-cy="fmea-risk-list"
              getRowId={(row) => row.id}
            >
              <Filtering.FormContent />
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete risk"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
