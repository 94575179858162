import React, { useMemo } from "react";

import { isRiskPolicy } from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  QButton,
  QCheckbox,
  QFormControl,
  QInput,
  QModal,
  QModalBody,
  QModalHeader,
  QRadio,
  QRadioGroup,
  QSpacer,
  QStack,
  QText,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { useRiskModal } from "../../hooks/useRiskModal";
import { CreateRiskForm } from "./types";

export const RiskModal: React.FC = () => {
  const { isOpen, closeModal } = useRiskModal();
  const configs = useCurrentConfigs();

  const methods = useForm<CreateRiskForm>({
    defaultValues: {
      type: "risk",
      subType: "iso",
      title: "",
      addAnother: false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(CreateRiskForm),
  });

  const showModels = useMemo(() => {
    const riskModel = configs.find(isRiskPolicy);
    return !!riskModel?.assessment?.fmea;
  }, [configs]);

  return (
    <QModal isOpen={isOpen} onClose={closeModal} size="xl">
      <QModalHeader>
        <QText>Create risk</QText>
      </QModalHeader>
      <QModalBody>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(console.log)}>
            <QStack gap="4">
              <QFormControl label="Risk name" isRequired>
                <Controller
                  name="title"
                  render={({ field: { onChange, value } }) => (
                    <QInput onChange={onChange} value={value} />
                  )}
                />
              </QFormControl>

              {showModels && (
                <QFormControl label="Risk model" isRequired>
                  <Controller
                    name="subType"
                    render={({ field: { onChange, value } }) => (
                      <QRadioGroup onChange={onChange} value={value}>
                        <QStack columnGap="16px" direction="row" rowGap={0}>
                          <QRadio value="iso" isChecked={value === "iso"}>
                            ISO 14971
                          </QRadio>
                          <QRadio value="fmea" isChecked={value === "fmea"}>
                            FMEA
                          </QRadio>
                        </QStack>
                      </QRadioGroup>
                    )}
                  />
                </QFormControl>
              )}

              <QStack justifyContent="space-between" direction="row">
                <Controller
                  name="addAnother"
                  render={({ field: { value, onChange } }) => (
                    <QCheckbox
                      size="sm"
                      data-cy="add-another-checkbox"
                      onChange={onChange}
                      value={value}
                    >
                      Add another
                    </QCheckbox>
                  )}
                />

                <QSpacer />
                <QStack direction="row">
                  <QButton
                    variant="outline"
                    data-cy="cancel-button"
                    onClick={closeModal}
                    isDisabled={false}
                  >
                    Cancel
                  </QButton>
                  <QButton
                    data-cy="submit-button"
                    type="submit"
                    isLoading={false}
                    isDisabled={false}
                  >
                    Create risk
                  </QButton>
                </QStack>
              </QStack>
            </QStack>
          </form>
        </FormProvider>
      </QModalBody>
    </QModal>
  );
};
