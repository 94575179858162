import React, { useState, useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { useCurrentUser } from '../../../../hooks';
import { DataContext } from '../context';
import type { DataProvider } from '../types';
import { useInfiniteQuery, useQuery } from 'react-query';
import { SearchResource } from '../../../../types/domains/Search';
import {
  V2QriResolverResponseSchema,
  V2ResponseSchema,
  V2Result,
} from './types';
import { useDebounce } from '../../../../hooks/useDebounce';

type QueryClauseType = Record<string, string | number | boolean>;
type QueryClauseWithMultiplerTerms = Record<string, Array<string | number>>;

export type QuickSearchQueryClause = {
  match?: QueryClauseType;
  term?: QueryClauseType;
  terms?: QueryClauseWithMultiplerTerms;
};

export type QuickSearchV2DataProviderProps<T extends SearchResource> = {
  /**
   * QuickSearch API client. Used to fetch search results.
   */
  quickSearchClient: AxiosInstance;
  /**
   * QRI Service api client. Used to resolve previously selected QRIs.
   */
  qriClient: AxiosInstance;
  /**
   * The Qualio resource type to search for, must be a valid SearchResource or an array of valid SearchResource.
   */
  resource: T | readonly T[];
  /**
   * Optional resource sub type to limit search to.
   */
  resourceSubType?: string;
  /**
   * Raw opensearch MUST clause
   * see https://opensearch.org/docs/latest/query-dsl/compound/bool/
   */
  must?: readonly QuickSearchQueryClause[];
  /**
   * Raw opensearch SHOULD clause
   * see https://opensearch.org/docs/latest/query-dsl/compound/bool/
   */
  should?: readonly QuickSearchQueryClause[];
  /**
   * List of QRIs to pre-populate the results in addition to the query
   */
  defaultDataQRIs?: readonly string[];
  children: React.ReactNode;
};

const PAGE_SIZE = 10;

export const QuickSearchV2 = <T extends SearchResource>({
  quickSearchClient,
  qriClient,
  resource,
  resourceSubType,
  must,
  should,
  defaultDataQRIs,
  children,
}: QuickSearchV2DataProviderProps<T>): React.ReactElement => {
  const { companyId } = useCurrentUser();
  const [searchTerm, setSearchTerm] = useState('');
  const qriResult = useQuery(['QriDetails', defaultDataQRIs], async () => {
    if (!defaultDataQRIs || defaultDataQRIs.length === 0) {
      return undefined;
    }
    const response = await qriClient.post('/details', {
      qris: defaultDataQRIs,
    });

    return Object.values(V2QriResolverResponseSchema.parse(response.data));
  });

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data, ...result } = useInfiniteQuery({
    queryKey: [
      'QuickSearchV2',
      resource,
      companyId,
      debouncedSearchTerm,
      should,
      must,
      PAGE_SIZE,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await quickSearchClient.get(`/v2/quicksearch`, {
        params: {
          companyId,
          term: debouncedSearchTerm,
          resources: Array.isArray(resource) ? resource.join(',') : resource,
          resourceSubType,
          should: should ? JSON.stringify(should) : undefined,
          must: must ? JSON.stringify(must) : undefined,
          size: PAGE_SIZE,
          page: pageParam,
        },
      });
      return V2ResponseSchema.parse(response.data);
    },
    getNextPageParam: (currentPage) => {
      return currentPage.results.length > 0 ? currentPage.page + 1 : undefined;
    },
  });

  const allData = useMemo(() => {
    const qriSearchResults = qriResult.data ?? [];
    const queryResults = data?.pages.flatMap((page) => page.results) ?? [];
    return [...qriSearchResults, ...queryResults];
  }, [data, qriResult]);

  return (
    <DataContext.Provider
      value={
        {
          data: allData,
          onSearchTermChange: setSearchTerm,
          hasNextPage: result.hasNextPage ?? false,
          ...result,
          isLoading: qriResult.isLoading || result.isLoading,
          error: qriResult.error ?? result.error,
        } satisfies DataProvider<V2Result>
      }
    >
      {children}
    </DataContext.Provider>
  );
};
