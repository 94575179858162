import React from 'react';
import { QBox, QFlex, QSpacer } from '../../QLayouts';
import { QButtonGroup, QDivider } from '../../QAtoms';
import { partitionChildren } from '../../utils';

/**
 * Renders a footer below a divider.
 *
 * If a `QDestructiveButton` is provided, it will be placed on the left side of the footer.
 * All other children will be placed within a `QButtonGroup` on the right side of the footer.
 */
export const QFooter: React.FC = ({ children }) => {
  const { QDestructiveButton, unmatched } = partitionChildren(
    children,
    'QDestructiveButton',
  );
  return (
    <QBox w="100%" alignSelf="end">
      <QDivider marginY="24px" />
      <QFlex>
        {QDestructiveButton}
        <QSpacer />
        <QButtonGroup>{unmatched}</QButtonGroup>
      </QFlex>
    </QBox>
  );
};
QFooter.displayName = 'QFooter';
