import { z } from "zod";

export const Attachment = z.object({
  filename: z.string(),
  filesize: z.number().max(104_857_600, "File cannot be bigger than 100MB"), //  In bytes
  contentType: z.string(),
});

export type Attachment = z.infer<typeof Attachment>;

export const AttachmentWithId = Attachment.merge(
  z.object({ id: z.string().uuid() }),
);

export type AttachmentWithId = z.infer<typeof AttachmentWithId>;

export const PresignedUrlResponse = z.object({
  attachment: AttachmentWithId,
  presignedUrl: z.string(),
});

export type PresignedUrlResponse = z.infer<typeof PresignedUrlResponse>;
