import React from "react";
import {
  FileSizeInBytes,
  QBodyLayout,
  QButton,
  QButtonGroup,
  QCard,
  QDivider,
  QFooter,
  QFormControl,
  QHeader,
  QInput,
  QMultiFileInput,
  QSelect,
  QStack,
  QText,
  QTitle,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Editor } from "../../../components";

export const EditRequirement: React.FC = () => {
  const methods = useForm({
    defaultValues: {
      title: "",
      attachments: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  return (
    <QBodyLayout.Default size="sm">
      <QHeader>
        <QTitle>Example requirement</QTitle>
      </QHeader>
      <FormProvider {...methods}>
        <form>
          <QStack gap="24px">
            <QText fontStyle="italic">
              Required fields are marked with an asterisk (
              <QText color="red.500" as="span">
                *
              </QText>
              )
            </QText>
            <QFormControl label="Title" isRequired>
              <Controller
                name="title"
                render={({ field: { value, onChange } }) => (
                  <QInput value={value} onChange={onChange} />
                )}
              />
            </QFormControl>
            <QFormControl label="Type" isRequired>
              <Controller
                name="type"
                render={({ field: { value, onChange } }) => (
                  <QSelect value={value} onChange={onChange} options={[]} />
                )}
              />
            </QFormControl>
            <QFormControl label="Component">
              <Controller
                name="component"
                render={({ field: { value, onChange } }) => (
                  <QSelect value={value} onChange={onChange} options={[]} />
                )}
              />
            </QFormControl>
            <QFormControl label="Category">
              <Controller
                name="type"
                render={({ field: { value, onChange } }) => (
                  <QSelect value={value} onChange={onChange} options={[]} />
                )}
              />
            </QFormControl>
            <QFormControl label="Description">
              <Controller
                name="description"
                render={({ field: { value, onChange } }) => (
                  <Editor onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
            <QDivider />
            <QStack gap="16px">
              <QStack gap="8px">
                <QText fontSize="lg" fontWeight={600}>
                  Related design elements
                </QText>
                <QText fontSize="sm">
                  You may link any related requirements and validation test
                  cases.
                </QText>
              </QStack>
              <QCard>
                <QStack padding={4} gap={4}>
                  <QStack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <QText fontSize="sm" fontWeight={600}>
                      Requirements
                    </QText>
                    <QButton variant="link">Add requirements</QButton>
                  </QStack>
                </QStack>
              </QCard>
              <QCard>
                <QStack padding={4} gap={4}>
                  <QStack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <QText fontSize="sm" fontWeight={600}>
                      Test cases
                    </QText>
                    <QButton variant="link">Add test cases</QButton>
                  </QStack>
                </QStack>
              </QCard>
            </QStack>
            <QDivider />
            <QText fontSize="lg" fontWeight={600}>
              Attachments
            </QText>
            <QFormControl>
              <Controller
                name="attachments"
                render={({ field: { value, onChange } }) => (
                  <QMultiFileInput
                    maxFiles={10}
                    maxFileSize={FileSizeInBytes.mb(100)}
                    maxCombinedSize={FileSizeInBytes.mb(500)}
                    description="Up to 100 MB per file"
                    value={value}
                    onChange={onChange}
                    onUploadFile={(file: File) =>
                      Promise.resolve({
                        filename: file.name,
                        filesize: file.size,
                        id: file.name,
                      })
                    }
                  />
                )}
              />
            </QFormControl>
          </QStack>
        </form>
      </FormProvider>
      <QFooter>
        <QButtonGroup>
          <QButton variant="outline">Cancel</QButton>
          <QButton>Save changes</QButton>
        </QButtonGroup>
      </QFooter>
    </QBodyLayout.Default>
  );
};
