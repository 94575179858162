import { QTooltip } from '../../../QAtoms';
import { QIcon } from '../../../QIcons';
import { SortDirection } from '@tanstack/react-table';
import React from 'react';
import { chakra } from '@chakra-ui/react';

export type SortingIconsProps = {
  direction: SortDirection | false;
};

export const SortingIcon: React.VFC<SortingIconsProps> = ({ direction }) => {
  const icon = (
    <chakra.span
      visibility={direction === false ? 'hidden' : undefined}
      aria-hidden={direction === false}
      marginLeft="1"
      position="relative"
      top="3px"
    >
      <QIcon
        iconName={direction === 'asc' ? 'ArrowUp' : 'ArrowDown'}
        size="sm"
        color="gray.500"
      />
    </chakra.span>
  );

  return direction === false ? (
    icon
  ) : (
    <QTooltip label={direction === 'asc' ? 'Sorted A → Z' : 'Sorted Z → A'}>
      {icon}
    </QTooltip>
  );
};
