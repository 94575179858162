import React, { useMemo } from "react";

import { ProductType, ProductWithoutId } from "@design-controls/types";
import {
  QFormControl,
  QHeading,
  QInput,
  QMultiSelect,
  QStack,
  QText,
} from "@qualio/ui-components";
import { Controller, useFormContext } from "react-hook-form";

import { RadioGroup } from "../../../components_new/RadioGroup/RadioGroup";
import { useTags } from "../../../hooks/documents/useTags";

export const ProductProfile: React.FC = () => {
  const { data: tags, isLoading: isLoadingTags } = useTags();
  const {
    formState: { errors },
  } = useFormContext<ProductWithoutId>();

  const tagOptions = useMemo(() => {
    return (tags ?? []).map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [tags]);

  return (
    <QStack gap={4} width={640}>
      <QStack>
        <QHeading size="md" fontWeight={600}>
          Product profile
        </QHeading>
        <QText fontSize="sm" fontWeight={400}>
          <QText as="span" color="red.500">
            *
          </QText>{" "}
          required fields
        </QText>
      </QStack>
      <QFormControl
        label="Product name"
        isRequired
        error={errors?.name?.message}
        isInvalid={!!errors?.name}
      >
        <Controller
          name="name"
          render={({ field: { onChange, value } }) => (
            <QInput value={value} onChange={onChange} data-cy="product-name" />
          )}
        />
      </QFormControl>
      <QFormControl
        label="Product type"
        error={errors?.type?.message}
        isInvalid={!!errors?.type}
      >
        <Controller
          name="type"
          render={({ field: { value, onChange, name } }) => (
            <RadioGroup
              defaultValue={value}
              name={name}
              onChange={onChange}
              options={ProductType.options.map((option) => ({
                label: option.value,
                value: option.value,
              }))}
            />
          )}
        />
      </QFormControl>
      <QFormControl
        label="Document tags"
        helper="Documents must contain all of your selected tags to appear in the documents tab."
        data-cy="document-tags-multiselect"
      >
        <Controller
          name="tags"
          render={({ field: { value, onChange } }) => (
            <QMultiSelect
              options={tagOptions}
              isLoading={isLoadingTags}
              value={value}
              onChange={(values) => onChange(values.map((item) => item.value))}
            />
          )}
        />
      </QFormControl>
    </QStack>
  );
};
