import { RowData } from '@tanstack/react-table';
import React from 'react';
import { QTooltip } from '../../../QAtoms';
import { getDataCy, getMetaField } from './meta';
import { CellProps } from './types';
import { QIcon } from '../../../QIcons';
import { IconNameType } from 'types/Icons';

export function QIconCell<TData extends RowData>({
  getValue,
  column,
  row: { index, original },
}: CellProps<TData, IconNameType | undefined>): React.ReactElement {
  const { tooltip, color } = getMetaField(column, 'icon');
  const iconName = getValue();

  if (!iconName) {
    return <></>;
  }

  const tooltipContent = tooltip?.(original);

  const element = (
    <QIcon
      iconName={iconName}
      color={color instanceof Function ? color(original, index) : color}
      data-cy={getDataCy(column, original)}
    />
  );

  return tooltipContent ? (
    <QTooltip label={tooltipContent}>{element}</QTooltip>
  ) : (
    element
  );
}
