import { BaseDesignElement } from "@design-controls/types";
import { z } from "zod";

const ModalForm = z.object({
  addAnother: z.boolean(),
});

export const CreateRequirementForm = BaseDesignElement.pick({ title: true })
  .merge(ModalForm)
  .extend({
    type: z.union([z.literal("req1"), z.literal("req2"), z.literal("req3")]),
  });
export type CreateRequirementForm = z.infer<typeof CreateRequirementForm>;

export const CreateTestCaseForm = BaseDesignElement.pick({ title: true })
  .merge(ModalForm)
  .extend({
    type: z.union([
      z.literal("testCase1"),
      z.literal("testCase2"),
      z.literal("testCase3"),
      z.literal("testCase"),
    ]),
  });
export type CreateTestCaseForm = z.infer<typeof CreateTestCaseForm>;

export const CreateRiskForm = BaseDesignElement.pick({ title: true })
  .merge(ModalForm)
  .extend({
    type: z.literal("risk"),
    subType: z.union([z.literal("iso"), z.literal("fmea")]),
  });
export type CreateRiskForm = z.infer<typeof CreateRiskForm>;
