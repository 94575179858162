import React from "react";

import { useStore } from "zustand";

import {
  CurrentProductContext,
  ProductContext,
} from "../context/currentProduct";

export const useProductStore = <T>(selector: (state: ProductContext) => T) => {
  const store = React.useContext(CurrentProductContext);
  if (!store) {
    throw new Error("missing CurrentProductContext");
  }

  return useStore(store, selector);
};
