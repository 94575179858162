import React from 'react';

import { QProgress, QText } from '../../QAtoms';
import { QStack } from '../../QLayouts';
import { QIcon } from '../../QIcons';

export const UploadInProgress: React.VFC = () => {
  return (
    <QStack alignItems="center" spacing="10px">
      <QStack direction="row" alignItems="center">
        <QIcon iconName="Upload" color="blue.500" />
        <QText fontSize="sm" color="blue.500">
          Uploading your file
        </QText>
      </QStack>
      <QProgress width="300px" colorScheme="blue" size="xs" isIndeterminate />
    </QStack>
  );
};
