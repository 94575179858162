import React, { useCallback, useMemo } from "react";
import { useRequirementPolicy } from "../../../hooks/createProductSteps/useRequirementPolicy";
import { TestCasePolicies } from "../../../types/createProductStore";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TestCasePolicy } from "../../../components/TestCasePolicy/TestCasePolicy";
import { useTestCasePolicy } from "../../../hooks/createProductSteps/useTestCasePolicy";

type Props = {
  nextRef: React.RefObject<any>;
};

export const TestCases: React.FC<Props> = ({ nextRef }) => {
  const { policies: requirementPolicies } = useRequirementPolicy();
  const { policies, storeTestCasePolicies } = useTestCasePolicy();

  const methods = useForm<TestCasePolicies>({
    defaultValues: policies,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(TestCasePolicies),
  });

  const requirements = useMemo(() => {
    const items: string[] = [requirementPolicies.req1.type];
    if (requirementPolicies.req2) {
      items.push(requirementPolicies.req2.type);
    }
    if (requirementPolicies.req3) {
      items.push(requirementPolicies.req3.type);
    }
    return items;
  }, [requirementPolicies]);

  const submit = useCallback(
    (formdata: TestCasePolicies) => {
      let hasErrors = false;
      const prefixes = new Set(
        Object.values(requirementPolicies).map((policy) => policy.codePrefix),
      );

      Object.values(formdata)
        .filter((item) => item !== undefined)
        .forEach((policy) => {
          if (prefixes.has(policy.codePrefix)) {
            methods.setError(`${policy.type}.codePrefix`, {
              message: "Prefix in use",
            });
            hasErrors = true;
          }

          prefixes.add(policy.codePrefix);
        });

      if (!hasErrors) {
        storeTestCasePolicies(formdata);
      }
    },
    [requirementPolicies, storeTestCasePolicies, methods.setError],
  );

  return (
    <FormProvider {...methods}>
      <TestCasePolicy requirements={requirements} />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(submit)}
      />
    </FormProvider>
  );
};
