import React from "react";

import { QFormControl, QInput } from "@qualio/ui-components";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import itemsApi from "../../api/items";
import { Loading } from "../../components_new/Loading";
import { useCompanyId } from "../../context/CurrentUserContext";
import { useProduct } from "../../context/product";
import { ProductRequirementParam } from "../../paramTypes";
import {
  FormRelatedElementType,
  FormRequirement,
  ProductRequirementFormRelatedElementsType,
} from "../../types/requirement";
import { RequirementTrace } from "../../types/trace";
import ProductRequirement from "./component/ProductRequirement";

const UpdateProductRequirement: React.FC = () => {
  const { requirementId } = useParams() as ProductRequirementParam;
  const company = useCompanyId();
  const navigate = useNavigate();
  const product = useProduct();

  const {
    isLoading,
    data: requirement,
    isLoadingError,
  } = useQuery<RequirementTrace | undefined>(
    `requirement-${requirementId}`,
    () =>
      itemsApi.queryById<RequirementTrace | undefined>(
        company,
        product.id,
        requirementId,
      ),
    { cacheTime: 0 },
  );

  const { mutate, isLoading: isUpdatingRequirement } = useMutation(
    ({ payload }: { payload: FormRequirement }) =>
      itemsApi.update(company, product.id, {
        data: { ...payload, id: requirementId },
      }),
    {
      onSuccess: () => {
        navigate(`/product/${product.id}/requirement`);
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isLoadingError || !requirement) {
    return <p>Perhaps redirect</p>;
  }

  const onSubmit = async (requirement: FormRequirement) => {
    await mutate({ payload: requirement });
  };

  const getRelatedElements = () => {
    const relatedElements: ProductRequirementFormRelatedElementsType = {
      req1: [],
      req2: [],
      req3: [],
      req4: [],
      testCase: [],
      testCase1: [],
      testCase2: [],
      testCase3: [],
    };
    ["parent", "children"].forEach((key) =>
      requirement[
        key as keyof Pick<RequirementTrace, "children" | "parent">
      ]?.forEach((item: RequirementTrace) => {
        (
          relatedElements[
            item.type as keyof ProductRequirementFormRelatedElementsType
          ] as Array<FormRelatedElementType>
        ).push({
          code: item.code ?? "",
          label: item.codeTitle || "",
          value: item.id,
        });
      }),
    );

    return relatedElements;
  };

  return (
    <ProductRequirement
      product={product}
      onSubmit={onSubmit}
      values={{
        title: requirement.title,
        category: requirement.category,
        component: requirement.component,
        type: requirement.type,
        description: requirement.description || "",
        statusLabel: requirement.statusLabel,
        ...getRelatedElements(),
        attachments: requirement.attachments,
      }}
      isSubmitting={isUpdatingRequirement}
    >
      <QFormControl label={"ID"}>
        <div style={{ marginBottom: "24px" }}>
          <QInput
            isDisabled={true}
            {...{ role: "id-textbox", name: "idTextBox" }}
            data-cy="id"
            width={150}
            value={requirement?.code}
          />
        </div>
      </QFormControl>
    </ProductRequirement>
  );
};

export default UpdateProductRequirement;
