import { useMemo } from 'react';

import { Product } from '@design-controls/types';
import { useMutation } from 'react-query';

import configApi from '../api/config';
import productApi from '../api/product';
import { filterValidationErrors } from '../lib/formValidationUtilities';
import { BaseQualityType, DesignElementType } from '../types/baseQualityItem';
import { CreateProductFlowConfiguration } from '../types/createProductFlow';
import { ProductConfiguration } from '../types/product';
import { BaseQualityTypeConfig, RiskQualityTypeConfig } from '../types/qualityTypeConfig';
import { RequestValidationError } from '../types/requestValidationError';
import {
  mapRequirementToBaseQualityType,
  mapRiskConfigurationTORiskQualityType,
  mapTestCaseToDesignElementConfigType,
} from '../types/riskConfiguration';

type UseCreateProductType = {
  onSubmit: (productPayload: CreateProductFlowConfiguration, options: OnSubmit) => Promise<void>;
  isLoading: boolean;
};

type OnSubmit = {
  onSaved?: (product: Product) => void;
  onFailure?: (errors: Array<RequestValidationError>) => void;
};

export const useCreateProduct = (companyId: number): UseCreateProductType => {
  const { mutateAsync: createProduct, isLoading: createProductIsLoading } = useMutation(
    ({ product }: { product: ProductConfiguration }) => productApi.create(companyId, product),
  );

  const configurationMap: Record<string, BaseQualityType> = {
    req1: DesignElementType.REQ1,
    req2: DesignElementType.REQ2,
    req3: DesignElementType.REQ3,
    req4: DesignElementType.REQ4,
    testCase1: DesignElementType.TEST_CASE_1,
    testCase2: DesignElementType.TEST_CASE_2,
    testCase3: DesignElementType.TEST_CASE_3,
  };

  const { mutateAsync: createRiskConfig, isLoading: createRiskConfigIsLoading } = useMutation(
    ({ productId, payload }: { productId: string; payload: RiskQualityTypeConfig }) =>
      configApi.createConfig(companyId, productId, DesignElementType.RISK, payload),
  );

  const { mutateAsync: createConfiguration, isLoading: createConfigurationIsLoading } = useMutation(
    ({
      productId,
      designElementPayload,
      type,
    }: {
      productId: string;
      designElementPayload: BaseQualityTypeConfig;
      type: BaseQualityType;
    }) => configApi.createConfig(companyId, productId, type, designElementPayload),
  );

  const onSubmit = async (
    productPayload: CreateProductFlowConfiguration,
    { onSaved, onFailure }: OnSubmit,
  ): Promise<void> => {
    try {
      const productResponse = await createProduct({ product: productPayload.product });

      const requirements = Object.entries(productPayload.requirement.items).map(([type, config]) =>
        createConfiguration({
          productId: productResponse.id,
          designElementPayload: mapRequirementToBaseQualityType(config),
          type: configurationMap[type],
        }),
      );

      const testCases = Object.entries(productPayload.testCase.testCases).map(([type, config]) =>
        createConfiguration({
          productId: productResponse.id,
          designElementPayload: mapTestCaseToDesignElementConfigType(config),
          type: configurationMap[type],
        }),
      );

      const promises: Array<Promise<RiskQualityTypeConfig | BaseQualityTypeConfig>> = [
        createRiskConfig({
          productId: productResponse.id,
          payload: mapRiskConfigurationTORiskQualityType(productPayload.risk),
        }),
      ];
      promises.push(...requirements, ...testCases);

      await Promise.allSettled(promises).then((results: any) => {
        const rejected = results
          .filter((result: any) => result.status === 'rejected')
          .map((result: any) => result.reason);
        if (rejected.length > 0) {
          throw rejected;
        }
        return results;
      });

      onSaved && onSaved(productResponse);
    } catch (e: any) {
      onFailure && onFailure(filterValidationErrors(e));
    }
  };

  const isLoading = useMemo(
    () => createProductIsLoading || createRiskConfigIsLoading || createConfigurationIsLoading,
    [createProductIsLoading, createRiskConfigIsLoading, createConfigurationIsLoading],
  );

  return {
    onSubmit,
    isLoading,
  };
};
