import { z } from "zod";

export enum ReviewStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
}

export const ReviewStatus = z.nativeEnum(ReviewStatusEnum);
export type ReviewStatus = z.infer<typeof ReviewStatus>;

export const ReviewList = z.object({
  code: z.string(),
  created: z.string(),
  createdBy: z.object({
    fullName: z.string(),
    id: z.string(),
  }),
  description: z.string(),
  id: z.string(),
  status: ReviewStatus,
  updated: z.string(),
  title: z.string(),
});
export type ReviewList = z.infer<typeof ReviewList>;
