import React, { useMemo, useState } from "react";

import { Risk } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QStack,
  QTag,
} from "@qualio/ui-components";
import { ItemStatusTag } from "../ItemStatusTag/ItemStatusTag";

const columnHelper = createQColumnHelper<Risk>();

type Props = {
  risks: Risk[];
};

export const RisksTable: React.FC<Props> = ({ risks }) => {
  const [expanded, setExpanded] = useState(true);

  const columns = useMemo(
    () => [
      columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
      columnHelper.text("title", { header: "Title", minWidth: "200px" }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
        maxWidth: "150px",
      }),
      {
        header: "Type",
        meta: {
          maxWidth: "150px",
        },
        cell: ({ row: { original: row } }: { row: { original: Risk } }) => {
          return (
            <QTag variantColor="gray">
              {!row.subType || row.subType === "iso" ? "ISO 14971" : "FMEA"}
            </QTag>
          );
        },
      },
      {
        header: "Status",
        meta: {
          maxWidth: "150px",
        },
        cell: ({ row: { original: row } }: { row: { original: Risk } }) => {
          return <ItemStatusTag itemStatus={row.itemStatus} />;
        },
      },
    ],
    [],
  );

  if (!risks.length) {
    return null;
  }

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Risks
        </QHeading>
        <QIconButton
          onClick={() => setExpanded((s) => !s)}
          iconName={expanded ? "ChevronUp" : "ChevronDown"}
          aria-label={expanded ? "Collapse risks" : "Expand risks"}
        />
      </QStack>
      <QCollapse isOpen={expanded}>
        <DataProvider.Fixed data={risks}>
          <QDataTable columns={columns} hideItemCount data-cy="risk-list" />
        </DataProvider.Fixed>
      </QCollapse>
    </QCard>
  );
};
