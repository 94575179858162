import React, { useCallback, useMemo } from "react";

import {
  QBodyLayout,
  QButton,
  QButtonGroup,
  QHeader,
  QMenuButton,
  QMenuItem,
  QStack,
  QTabs,
  QTitle,
} from "@qualio/ui-components";
import { useLocation, useNavigate } from "react-router";

import { RequirementModal } from "../components/CreateDesignElement/RequirementModal";
import { RiskModal } from "../components/CreateDesignElement/RiskModal";
import { TestCaseModal } from "../components/CreateDesignElement/TestCaseModal";
import { useCurrentProduct } from "../hooks/useCurrentProduct";
import { useRequirementModal } from "../hooks/useRequirementModal";
import { useRiskModal } from "../hooks/useRiskModal";
import { useTestCaseModal } from "../hooks/useTestCaseModal";
import { ManageProduct } from "../components/ManageProduct/ManageProduct";

const TABS: [string, string, string][] = [
  ["requirement", "requirement", "Requirements"],
  ["testCase", "test-case", "Tests"],
  ["risk", "risk", "Risks"],
  ["document", "document", "Documents"],
  ["reviews", "change-control", "Reviews"],
];

export const ListLayout: React.FC = ({ children }) => {
  const product = useCurrentProduct();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openModal: openRequirementModal } = useRequirementModal();
  const { openModal: openTestCaseModal } = useTestCaseModal();
  const { openModal: openRiskModal } = useRiskModal();

  const onItemChange = useCallback(
    (index: number) => {
      const item = TABS.at(index);
      if (!item) {
        return;
      }

      navigate(`/product/${product.id}/${item[1]}`);
    },
    [navigate, product],
  );

  const activeTabId = useMemo(() => {
    const item = TABS.find(([, routePath]) => pathname.includes(routePath));

    if (!item) {
      return "requirement";
    }

    return item[0];
  }, [pathname]);

  return (
    <QBodyLayout.Default>
      <>
        <QButton
          variant="link"
          leftIcon="ArrowLeft"
          onClick={() => navigate("/product")}
        >
          Back
        </QButton>
        <QHeader showDivider={false}>
          <QTitle>{product.name}</QTitle>
          <QButtonGroup>
            <QMenuButton buttonLabel="Create new" variant="solid">
              <QMenuItem onClick={openRequirementModal}>Requirement</QMenuItem>
              <QMenuItem onClick={openTestCaseModal}>Test case</QMenuItem>
              <QMenuItem onClick={openRiskModal}>Risk</QMenuItem>
            </QMenuButton>
            <ManageProduct />
          </QButtonGroup>
        </QHeader>
      </>
      <QStack gap={4}>
        <QTabs
          activeTabId={activeTabId}
          onChange={onItemChange}
          tabs={TABS.map(([id, , title]) => ({ id, title }))}
        />
        {children}
      </QStack>
      <RequirementModal />
      <TestCaseModal />
      <RiskModal />
    </QBodyLayout.Default>
  );
};
