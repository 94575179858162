import { DESIGN_CONTROLS_SOURCE } from '../../lib/source';
import { BaseQualityItem, DesignElementType, QualityItemStatus } from '../../types/baseQualityItem';
import { ControlledDocument } from '../../types/controlledDocument';
import { Requirement } from '../../types/requirement';
import { TestCase, TestCaseWithResult } from '../../types/testCase';
import { ResultOptions } from '../../views/TestCaseResult/TestCaseResult.enums';
import { makeResponse } from './generic';

export const req1QualityItems: Requirement[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'UN-3',
    created: '2020-12-14T10:35:59.724+0100',
    description:
      '<p>Given that a user has downloaded the mobile application and have an account the user can login using email &amp; password.</p>',
    id: '65e0bf69-d1d9-426b-aa3d-7362af3b2426',
    source: 'jira',
    title: 'Login',
    type: DesignElementType.REQ1,
    updated: '2021-02-01T15:25:47.943+0100',
    statusLabel: 'Validated',
    url: 'https://example.com/DD-19',
    codeTitle: 'UN-3 Login',
    changeControlStatus: 'unstaged',
  },
  {
    description: '<p>Outline the pipeline security.</p>',
    source: 'jira',
    id: '5c0c4cab-7c9a-467e-8123-ee98f0622d41',
    title: 'Production Pipeline Security',
    type: DesignElementType.REQ1,
    url: 'http://example.com/TRIAGE-280',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    codeTitle: 'UN-2 Security',
    code: 'UN-2',
  },
  {
    description:
      '<p>As a CureMetrix administrator I need a database that tracks events, errors, and metrics so that I can monitor the system.</p>',
    source: 'jira',
    id: '5e496589-85e6-41c9-8dfa-12e0227d5c31',
    title: 'TR-UN-0005- Metrics database',
    type: DesignElementType.REQ1,
    url: 'http://example.com/TRIAGE-68',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    codeTitle: 'UN-4 Database',
    code: 'UN-4',
  },
  {
    description:
      '<p>As a Hospital IT user I need a Cloud Platform that receives mammography DICOM studies from cm‌Edge clients, processes the images, and sends results back to the cm‌Edge client.</p>\n\n<p>I need for this Cloud Platform to securely store and transport the studies and results.</p>',
    source: 'jira',
    id: '7f10abf2-c1f3-4f51-bca1-93ed3785fc14',
    title: 'TR-UN-0003- Triage Cloud',
    type: DesignElementType.REQ1,
    url: 'http://example.com/TRIAGE-32',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    codeTitle: 'UN-6 Cloud',
    code: 'UN-6',
  },
  {
    description:
      '<p>As a Radiologist I need cmTriage to report back to the Mammography Viewing Station’s patient worklist with a code indicating ‘cmTriage: Suspicious’ for a suspicious study and the term ‘cmTriage:’ to indicate a study that was processed and deemed not suspicious.</p>',
    source: 'jira',
    id: 'bbc7d67b-e6e8-4964-a798-16b9c4bed77f',
    title: 'TR-UN-0001- cmTriage Structured Reports',
    type: DesignElementType.REQ1,
    url: 'http://example.com/TRIAGE-1',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    codeTitle: 'UN-7 Reports',
    code: 'UN-7',
  },
  {
    description:
      '<p>As a Hospital IT professional I need a DICOM-enabled Edge client installed on my network that can securely transport DICOM to the cmTriage Cloud Infrastructure and send the results back to the PACS/VNA in a secure and timely manner.</p>',
    source: 'jira',
    id: 'd58f6f70-f124-4eb0-a84b-f760a220e89c',
    title: 'TR-UN-0002- Edge client',
    type: DesignElementType.REQ1,
    url: 'http://example.com/TRIAGE-11',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    codeTitle: 'UN-8 Edge client',
    code: 'UN-8',
  },
];

export const req2QualityItems: Requirement[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'US-3',
    created: '2020-12-14T10:37:00.378+0100',
    description:
      '<p>The system shall enable the user to log into the application only with a registered user name &amp; associated password.</p>',
    id: 'f6729b6e-df04-4084-95d3-518ff17397db',
    source: 'jira',
    title: 'Login Security',
    type: DesignElementType.REQ2,
    updated: '2021-02-01T15:25:54.922+0100',
    statusLabel: 'Done',
    url: 'https://example.com/DD-20',
    codeTitle: 'US-3 Login Security',
    changeControlStatus: 'unstaged',
  },
];

export const req3QualityItems: Requirement[] = [
  {
    description: '<p>Outline the pipeline security.</p>',
    source: 'jira',
    id: '5c0c4cab-7c9a-467e-8123-ee98f0622d40',
    title: 'Production Pipeline Security',
    type: DesignElementType.REQ3,
    url: 'http://example.com/TRIAGE-280',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
];

export const req4QualityItems: Requirement[] = [
  {
    description: '<p>Outline the pipeline security.</p>',
    source: 'jira',
    id: '5c0c4cab-7c9a-467e-8123-ee98f0622d40',
    title: 'Production Pipeline Security',
    type: DesignElementType.REQ4,
    url: 'http://example.com/TRIAGE-280',
    statusLabel: 'To Do',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
  },
];

export const documentQualityItems: ControlledDocument[] = [
  {
    id: '63cbaf7e-440a-4c7f-ac16-8069d8d0ca30',
    code: 'DOC-42',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    statusLabel: 'Some Label',
    source: 'Jira',
    title: 'Some Document',
    url: 'http://example.com',
    version: '2.0',
    type: DesignElementType.DOCUMENT,
    changeControlStatus: 'unstaged',
    itemStatus: QualityItemStatus.NEW,
    approver: [{ name: 'John Doe' }, { name: 'Sam Sepiol' }],
    reviewer: [{ email: 'angela.moss@example.com' }],
  },
];

export const testCaseQualityItems: TestCase[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'TC-2',
    created: '2020-12-14T10:39:46.820+0100',
    description:
      '<p>Negative test to check how the app behaves if the user tries to login to the app by entering an invalid email id or password</p>\n\n<p>1) Launch the application.<br/>\n2) Enter an invalid Email ID or password of the user submitted at the time of Trial sign up.</p>\n\n<p>The login attempt should not be successful and the following error message should appear on the screen:<br/>\n"Username or password is wrong. Please try again."</p>',
    id: '3ced17fc-f79f-4464-8463-1190f02383d2',
    source: 'jira',
    title: 'Verify customer login using invalid login credentials',
    type: DesignElementType.TEST_CASE,
    updated: '2021-02-01T15:26:17.669+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-22',
    codeTitle: 'TC-2 Verify customer login using invalid login credentials',
    changeControlStatus: 'unstaged',
  },
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'TC-4',
    created: '2020-12-14T10:38:55.961+0100',
    description:
      '<p>To verify if the user is able to login to the app by entering the email and password submitted by the user while registering</p>\n\n<p>1) Launch the application.<br/>\n2) Enter the Email ID and password of the user submitted at the time of Trial sign up.</p>\n\n<p>The user should be able to login to the app successfully.</p>',
    id: '64a97675-d6fd-435e-bd6a-4bef12711a65',
    source: 'jira',
    title: 'Verify customer login using valid login credentials',
    type: DesignElementType.TEST_CASE,
    updated: '2021-02-01T15:26:04.858+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-21',
    codeTitle: 'TC-4 Verify customer login using valid login credentials',
    changeControlStatus: 'unstaged',
  },
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'TC-1',
    created: '2020-12-14T10:40:31.023+0100',
    description:
      '<p>Negative test to check how the app behaves if the user tries to login to the app without entering any email ID.</p>\n\n<p>1) Launch the app.<br/>\n2) Don\'t enter an Email ID.<br/>\n3) Enter the password of the user submitted at the time of Trial sign up.</p>\n\n<p>The login attempt should not be successful and the following error message should appear on the screen:<br/>\n"Email is required."</p>',
    id: 'ecbc38ea-8366-4ab5-8d8b-6cefdea130bd',
    source: 'jira',
    title: 'Verify customer login without entering any email ID',
    type: DesignElementType.TEST_CASE,
    updated: '2021-02-01T15:26:29.782+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-23',
    codeTitle: 'TC-1 Verify customer login without entering any email ID',
    changeControlStatus: 'unstaged',
  },
];

export const testCase1QualityItems: TestCaseWithResult[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'VAL-1',
    created: '2020-12-14T10:39:46.820+0100',
    description:
      '<p>Negative test to check how the app behaves if the user tries to login to the app by entering an invalid email id or password"</p>',
    id: '3ced17fc-f80f-4464-8463-1190f0238000',
    source: DESIGN_CONTROLS_SOURCE,
    title: 'Validate customer login using invalid login credentials',
    type: DesignElementType.TEST_CASE_1,
    updated: '2021-02-01T15:26:17.669+0100',
    statusLabel: 'Draft',
    url: 'https://example.com/DD-22',
    codeTitle: 'VAL-1 Validate customer login using invalid login credentials',
    changeControlStatus: 'unstaged',
    attachments: [],
    testResult: {
      result: ResultOptions.PASSED,
      executedBy: 'Test User',
      notes: '<p>Hello World</p>',
      date: new Date('2021-01-01').toISOString(),
    },
  },
];

export const testCase2QualityItems: TestCaseWithResult[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'VER-1',
    created: '2020-12-14T10:38:55.961+0100',
    description:
      '<p>To verify if the user is able to login to the app by entering the email and password submitted by the user while registering</p>\n\n<p>1) Launch the application.<br/>\n2) Enter the Email ID and password of the user submitted at the time of Trial sign up.</p>\n\n<p>The user should be able to login to the app successfully.</p>',
    id: '64a97675-d7fd-435e-bd6a-4bef12711000',
    source: 'jira',
    title: 'Verify customer login using valid login credentials',
    type: DesignElementType.TEST_CASE_2,
    updated: '2021-02-01T15:26:04.858+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-21',
    codeTitle: 'TC-4 Verify customer login using valid login credentials',
    changeControlStatus: 'unstaged',
    attachments: [],
  },
];

export const testCase3QualityItems: TestCaseWithResult[] = [
  {
    itemStatus: QualityItemStatus.NEW,
    code: 'TC-1',
    created: '2020-12-14T10:38:55.961+0100',
    description:
      '<p>To verify if the user is able to login to the app by entering the email and password submitted by the user while registering</p>\n\n<p>1) Launch the application.<br/>\n2) Enter the Email ID and password of the user submitted at the time of Trial sign up.</p>\n\n<p>The user should be able to login to the app successfully.</p>',
    id: '98b36b2c-908c-48d2-b6b4-85279a149a17',
    source: 'jira',
    title: 'Verify customer login using valid login credentials',
    type: DesignElementType.TEST_CASE_3,
    updated: '2021-02-01T15:26:04.858+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-21',
    codeTitle: 'TC-4 Verify customer login using valid login credentials',
    changeControlStatus: 'unstaged',
    attachments: [],
  },
];

export const testCaseDeletedQualityItems: TestCaseWithResult[] = [
  {
    itemStatus: QualityItemStatus.DELETED,
    code: 'VAL-2',
    created: '2020-12-14T10:39:46.820+0100',
    description:
      '<p>Negative test to check how the app behaves if the user tries to login to the app by entering an invalid email id or password"</p>',
    id: '3ced17fc-f80f-4464-8463-1190f0238000',
    source: DESIGN_CONTROLS_SOURCE,
    title: 'Validate customer login using invalid login credentials',
    type: DesignElementType.TEST_CASE_1,
    updated: '2021-02-01T15:26:17.669+0100',
    statusLabel: 'Passed',
    url: 'https://example.com/DD-22',
    codeTitle: 'VAL-2 Validate customer login using invalid login credentials',
    changeControlStatus: 'unstaged',
    attachments: [],
    testResult: {
      result: ResultOptions.PASSED,
      executedBy: 'Test User',
      notes: '<p>Hello World</p>',
      date: new Date('2021-01-01').toISOString(),
    },
  },
];

export const testCaseAndResultWithIssues = [
  {
    changeControlStatus: 'staged',
    itemStatus: 'NEW',
    code: 'VAL-21',
    created: '2023-01-30T13:52:59.706Z',
    description: '<div class="ck-content"><p>New TestCase - Eoghan</p></div>',
    id: '66aece39-9fea-4189-8874-7f0c09e9346c',
    source: 'product-development',
    statusLabel: 'Draft',
    testResult: {
      result: 'passed',
      date: '2023-01-30T13:53:21.405Z',
      executedBy: 'Test Result',
      source: 'product-development',
      notes: 'Test Result',
      attachments: [],
    },
    title: 'New TestCase - Eoghan - outdated',
    type: 'testCase1',
    updated: '2023-02-01T15:32:24.729Z',
    codeTitle: 'VAL-21 New TestCase - Eoghan - outdated',
    version: 'v3',
    attachments: [],
    parent: [],
    sourcedItems: [],
    mitigates: [],
    children: [],
    policyIssues: {
      UNTESTED_REQUIREMENT: false,
      UNMITIGATED: false,
      REQUIRES_CHILD: false,
      REQUIRES_PARENT: true,
      FAILING_TEST: false,
      OUTDATED_TESTRESULT: true,
    },
  },
];

function withVersion(item: BaseQualityItem) {
  return { ...item, version: 'v2' };
}
export const qualityItemResponseByType: Record<string, any> = {
  req1: makeResponse(req1QualityItems.map(withVersion)),
  req2: makeResponse(req2QualityItems.map(withVersion)),
  req3: makeResponse(req3QualityItems.map(withVersion)),
  req4: makeResponse(req4QualityItems.map(withVersion)),
  testCase1: makeResponse(testCase1QualityItems.map(withVersion)),
  testCase2: makeResponse(testCase2QualityItems.map(withVersion)),
  testCase3: makeResponse(testCase3QualityItems.map(withVersion)),
  document: makeResponse(documentQualityItems),
  testCase: makeResponse(testCaseQualityItems),
};
