import React, { useMemo } from "react";

import { QFormControl, QSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

import { useRetrieveUsers } from "../../../hooks/useRetrieveUsers";

export const Owner: React.FC = () => {
  const { isLoading, data } = useRetrieveUsers();

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((item) => ({
      value: item.id.toString(10),
      label: item.fullName,
    }));
  }, [data]);

  return (
    <Controller
      name="owner"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Owner">
          <QSelect
            value={value}
            isLoading={isLoading}
            onChange={(selectedItem) =>
              selectedItem && onChange(selectedItem.value)
            }
            options={options}
          />
        </QFormControl>
      )}
    />
  );
};
