import React, { useMemo } from "react";

import {
  QFormControl,
  QInput,
  QMultiSelect,
  QStack,
  QText,
} from "@qualio/ui-components";
import { Controller, useFormContext } from "react-hook-form";

import { RadioGroup } from "../../components_new/RadioGroup/RadioGroup";
import { useTags } from "../../hooks/documents/useTags";
import { productType } from "../../types/product";

const ProductInfoForm: React.FC = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const { data: tags, isLoading: isLoadingTags } = useTags();

  const tagOptions = useMemo(() => {
    return (tags ?? []).map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [tags]);

  return (
    <div data-cy="product-info-form">
      <QFormControl
        label="Product name *"
        isInvalid={!!errors.name}
        error={errors.name?.message}
      >
        <Controller
          name="name"
          render={({ field: { onChange, value } }) => (
            <QInput
              value={value}
              onChange={onChange}
              data-cy={`product-name`}
            />
          )}
        />
      </QFormControl>

      <QStack mb={5} mt={5} gap={2}>
        <QFormControl
          label={"Type"}
          isInvalid={!!errors.type}
          error={errors.type?.message}
        >
          <Controller
            name="type"
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                defaultValue={value}
                name="type"
                onChange={onChange}
                options={productType.options.map((option) => ({
                  label: option.value,
                  value: option.value,
                }))}
              />
            )}
          />
        </QFormControl>
        <QFormControl
          label="Document tags"
          isInvalid={!!errors.tags}
          data-cy="document-tags-multiselect"
        >
          <QStack mb={2}>
            <QText fontSize="sm" color="gray.900">
              Documents must contain all of your selected tags to appear in the
              documents tab.
            </QText>
          </QStack>
          <Controller
            name="tags"
            render={({ field: { onChange, value } }) => (
              <QMultiSelect
                options={tagOptions}
                isLoading={isLoadingTags}
                value={value}
                onChange={(values) =>
                  onChange(values.map((item) => item.value))
                }
              />
            )}
          ></Controller>
        </QFormControl>
      </QStack>
    </div>
  );
};

export default ProductInfoForm;
