import React from 'react';
import { QEmptyState } from '../EmptyState/EmptyState';
import { DataProps } from '../../utils';

export type QNotFoundProps = DataProps;

/**
  Not Found component serves as a fallback for cases where no routes in your application match the requested path
 */
export const QNotFound: React.FC<QNotFoundProps> = (props) => {
  return (
    <QEmptyState
      title="Looks like you don't have permission to access this page or it no longer exists."
      subtitle="If you think this shouldn’t happen, contact your manager or Qualio support."
      {...props}
    />
  );
};
