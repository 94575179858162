import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const YesNo = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg width="24" height="24" fill="none">
          <path
            d="m4.71777 7.50635 1.52491-2.93115h1.13818l-2.13574 3.92871v2.49609h-1.0503v-2.45654l-2.14013-3.96826h1.14697zm6.10403-2.93115-2.39504 6.4248h-.9668l2.39502-6.4248zm6.2798 6.4248h-1.2964l-3.1465-5.12842h-.0396c.0118.17285.022.35596.0308.54932.0117.19336.0205.39258.0264.59765.0088.20508.0161.41309.0219.62403v3.35742h-.958v-6.4248h1.2876l3.1421 5.09326h.0308c-.0059-.14942-.0132-.3208-.022-.51416s-.0176-.39258-.0264-.59766c-.0058-.20801-.0102-.40869-.0131-.60205v-3.37939h.9624z"
            fill="currentColor"
            color="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

YesNo.displayName = 'YesNo';
