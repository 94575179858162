import React from "react";

import { useStore } from "zustand";

import { CreateProductContext } from "../context/CreateProductContext";
import { CreateProductStore } from "../types/createProductStore";

export const useCreateProductStore = <T>(
  selector: (state: CreateProductStore) => T,
): T => {
  const store = React.useContext(CreateProductContext);
  if (!store) {
    throw new Error("Missing CreateProductProvider");
  }

  return useStore(store, selector);
};
