import { Filtering } from "@qualio/ui-components";

export const filterDefinitions = {
  owner: {
    label: "Owner",
    schema: Filtering.schemas.StringSchema(),
  },
  status: {
    label: "Status",
    schema: Filtering.schemas.StringSchema(),
  },
} as const;
