import React from 'react';

import { QButton, QText } from '../../QAtoms';

export const FileInputBody: React.VFC<{
  handleBrowse: () => void;
  description?: string;
}> = ({ handleBrowse, description }) => {
  return (
    <>
      <QText fontSize="sm" fontWeight="semibold">
        Drag and drop your file or
      </QText>
      <QButton variant="outline" onClick={handleBrowse}>
        Browse
      </QButton>
      {description && (
        <QText fontSize="sm" color="gray.500">
          {description}
        </QText>
      )}
    </>
  );
};
