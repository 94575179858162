import React, { useMemo } from 'react';
import { QBox, QStack } from '../../../QLayouts';
import { QIconButton, QSelect, QSelectItem, QText } from '../../../QAtoms';
import { PageSize, usePaginationMaybe } from '../Pagination';

const PAGE_SIZE_OPTIONS = [
  {
    label: '15',
    value: 15,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
] satisfies QSelectItem<string, PageSize>[];

export const Pagination: React.VFC = () => {
  const pagination = usePaginationMaybe();
  if (!pagination) {
    return null;
  }

  const { pageSize, pageIndex, itemCount, setPageIndex, setPageSize } =
    pagination;

  const hasMore = useMemo(() => {
    const total = itemCount ?? 0;
    if (total < pageSize) {
      return false;
    }

    return (pageIndex + 1) * pageSize < total;
  }, [pageSize, pageIndex, itemCount]);

  return (
    <QStack direction="row" align="center" spacing="8px">
      <QText fontSize="sm">Show</QText>
      <QBox w="77px">
        <QSelect
          isSearchable={false}
          options={PAGE_SIZE_OPTIONS}
          value={pageSize}
          onChange={(opt) => opt && setPageSize(opt?.value)}
        />
      </QBox>
      <QIconButton
        aria-label="previous page"
        iconName="ChevronLeft"
        onClick={() => setPageIndex(pageIndex - 1)}
        isDisabled={pageIndex === 0}
      />
      <QText fontSize="sm">Page {Math.trunc(pageIndex + 1)}</QText>
      <QIconButton
        aria-label="next page"
        iconName="ChevronRight"
        onClick={() => setPageIndex(pageIndex + 1)}
        isDisabled={!hasMore}
      />
    </QStack>
  );
};
