import React from 'react';
import { QIconButton } from '../../../QAtoms';
import { useQPropertiesPanelStateContext } from '../context';

/**
 * A button that toggles the properties panel when clicked.
 * Displays a left-pointing chevrons when the panel is closed,
 * and a right-facing chevron when the panel is open.
 */
export const QTogglePropertiesPanelButton: React.VFC = () => {
  const {
    disclosure: { isOpen, onToggle },
  } = useQPropertiesPanelStateContext();
  return (
    <QIconButton
      iconName={isOpen ? 'ChevronsRight' : 'ChevronsLeft'}
      aria-label="Toggle properties panel"
      onClick={onToggle}
    />
  );
};
