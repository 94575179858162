import React from 'react';
import { SpaceProps } from 'styled-system';
import { QFlex, QFlexProps } from '../../QLayouts';
import { LayoutProps } from '@chakra-ui/react';

export type QCardProps = Pick<
  QFlexProps,
  keyof SpaceProps | keyof Omit<LayoutProps, 'd' | 'display'>
>;

export const QCard: React.FC<QCardProps> = ({ children, ...rest }) => {
  return (
    <QFlex
      direction="column"
      rounded="4px"
      border="1px solid"
      borderColor="gray.200"
      {...rest}
    >
      {children}
    </QFlex>
  );
};
