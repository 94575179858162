import React, { useCallback, useMemo } from "react";

import {
  QButton,
  QFormControl,
  QHeading,
  QInput,
  QStack,
  QTag,
  QText,
} from "@qualio/ui-components";
import { Controller, FieldErrors, useFormContext } from "react-hook-form";

import { TestCasePolicies } from "../../types/createProductStore";
import { INITIAL_TESTCASE_CONFIGURATION } from "../../constants/testCasePolicy";
import { TestCaseLevel1Policy } from "@design-controls/types";

type TestCaseLevel = "testCase1" | "testCase2" | "testCase3";

type Props = {
  prefix: TestCaseLevel;
  label: string;
  disableLevel?: (level: TestCaseLevel) => void;
  enableLevel?: (level: TestCaseLevel) => void;
  isDisabled: boolean;
  errors?: FieldErrors<TestCaseLevel1Policy>;
};

const TestCaseLevelForm: React.FC<Props> = ({
  prefix,
  label,
  disableLevel,
  enableLevel,
  isDisabled,
  errors,
}) => {
  return (
    <QStack borderWidth={1} borderColor="gray.200" borderRadius={2}>
      <QStack padding={4}>
        <QStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <QHeading size="xs" fontWeight={600}>
            {label}
          </QHeading>
          {isDisabled && <QTag variantColor="gray">Disabled</QTag>}
        </QStack>
        {!isDisabled && (
          <QStack direction="row">
            <QFormControl
              label="Prefix"
              width="72px"
              isInvalid={!!errors?.codePrefix}
              error={errors?.codePrefix?.message}
            >
              <Controller
                name={`${prefix}.codePrefix`}
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Name"
              isInvalid={!!errors?.label}
              error={errors?.label?.message}
            >
              <Controller
                name={`${prefix}.label`}
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
          </QStack>
        )}
      </QStack>
      {!isDisabled && (
        <QStack backgroundColor="gray.50" padding={2}>
          <QButton
            variant="ghost"
            leftIcon="EyeOff"
            isDisabled={isDisabled}
            onClick={disableLevel ? () => disableLevel(prefix) : undefined}
          >
            Disable
          </QButton>
        </QStack>
      )}
      {isDisabled && (
        <QStack backgroundColor="gray.50" padding={2}>
          <QButton
            variant="ghost"
            leftIcon="Eye"
            onClick={enableLevel ? () => enableLevel(prefix) : undefined}
          >
            Enable
          </QButton>
        </QStack>
      )}
    </QStack>
  );
};

type TestCasePolicyProps = {
  requirements: readonly string[];
};

export const TestCasePolicy: React.FC<TestCasePolicyProps> = ({
  requirements,
}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<TestCasePolicies>();

  const levels = watch(["testCase1", "testCase2", "testCase3"]);

  const disableLevel = useCallback(
    (level: TestCaseLevel) => {
      setValue(level, undefined);
    },
    [setValue],
  );

  const enableLevel = useCallback(
    (level: TestCaseLevel) => {
      setValue(level, INITIAL_TESTCASE_CONFIGURATION[level], {
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const disabledLevels = useMemo(() => {
    return {
      testCase1: !levels[0],
      testCase2: !levels[1],
      testCase3: !levels[2],
    };
  }, [levels]);

  return (
    <QStack gap={4} width={640}>
      <QStack>
        <QHeading size="md" fontWeight={600}>
          Test cases
        </QHeading>
        <QText fontSize="sm">
          At least one test must be enabled. You can create and manage
          components and categories in the Resource Library to further organize
          your tests.
        </QText>
      </QStack>

      {requirements.includes("req1") && (
        <TestCaseLevelForm
          label="Level 1"
          isDisabled={disabledLevels["testCase1"]}
          prefix="testCase1"
          disableLevel={disableLevel}
          enableLevel={enableLevel}
          errors={errors.testCase1}
        />
      )}
      {requirements.includes("req2") && (
        <TestCaseLevelForm
          label="Level 2"
          isDisabled={disabledLevels["testCase2"]}
          prefix="testCase2"
          disableLevel={disableLevel}
          enableLevel={enableLevel}
          errors={errors.testCase2}
        />
      )}
      {requirements.includes("req3") && (
        <TestCaseLevelForm
          label="Level 3"
          isDisabled={disabledLevels["testCase3"]}
          prefix="testCase3"
          disableLevel={disableLevel}
          enableLevel={enableLevel}
          errors={errors.testCase3}
        />
      )}
    </QStack>
  );
};
