import React, { useCallback, useMemo, useState } from 'react';

import { QBadge, deprecated, QMenuButton, QMenuItem, QText } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

import { UserPermissions } from '../../components';
import { DeleteConfirmationModal } from '../../components/DeleteDesignElementModal/DeleteConfirmationModal';
import { FeatureFlags } from '../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../components/FeatureToggle/hooks/useFeatureFlag';
import useHasPermission from '../../components/UserGroupToggle/hooks/useHasPermission';
import { useCustomSorting } from '../../hooks/useCustomSorting';
import { sortByCode } from '../../lib/sortByCode';
import { sortByAlphanumericInsensitive, sortByDate, sortByRiskLevel } from '../../lib/sortFunctions';
import { DesignElementType, QualityItemStatus } from '../../types/baseQualityItem';
import { ISORiskType, RiskSubType } from '../../types/risk';
import { ClickPropagationBarrier } from '../ClickPropogateBarrier/ClickPropogateBarrier';
import { RiskEmptyState } from '../EmptyState/Risk';
import { PageTable } from '../PageTable/PageTable';
import { QTextWithHover } from '../ShortenTextAndHover/QTextWithHover';
import { DesignControlTag } from '../Tag/DesignControlTag';
import { RiskTag } from '../Tag/RiskTag';
import { getDesignControlStatus } from './lib/getDesignControlStatus';
import { riskTableDefaultSortBy } from './lib/riskTableDefaultSortBy';

export type ISORiskTableProps = {
  productId: string;
  riskLevels: Array<string>;
  onRowClick: (row: deprecated.QDataRow) => void;
  selectedItemId?: string;
  isLoading: boolean;
  designElements: Array<ISORiskType>;
  deleteDesignElement: (designElementId: string, type: DesignElementType) => void;
};

export const ISORiskTable: React.FC<ISORiskTableProps> = ({
  productId,
  riskLevels,
  onRowClick,
  selectedItemId,
  isLoading,
  designElements,
  deleteDesignElement,
}) => {
  const [elementToBeDeleted, setElementToBeDeleted] = useState<ISORiskType | undefined>(undefined);
  const permissions = useHasPermission([UserPermissions.EDIT_DESIGN_ELEMENTS, UserPermissions.DELETE_DESIGN_ELEMENTS]);
  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);

  const deleteElementCallback = useCallback(() => {
    if (elementToBeDeleted) {
      deleteDesignElement(elementToBeDeleted.id, elementToBeDeleted.type);
    }

    setElementToBeDeleted(undefined);
  }, [deleteDesignElement, elementToBeDeleted, setElementToBeDeleted]);
  const closeDeleteElementCallback = useCallback(() => {
    setElementToBeDeleted(undefined);
  }, [setElementToBeDeleted]);
  const navigate = useNavigate();

  const onEditClick = useCallback(
    (designElementId: string) => {
      navigate(`/product/${productId}/risk/edit/${designElementId}`);
    },
    [navigate, productId],
  );

  const { sortedData, handleSortByCallback } = useCustomSorting<ISORiskType>(
    { id: 'code', desc: false },
    {
      code: sortByCode,
      hazard: (firstISORisk, secondISORisk) => {
        return sortByAlphanumericInsensitive(firstISORisk.hazard, secondISORisk.hazard);
      },
      hazardSituation: (firstISORisk, secondISORisk) => {
        return sortByAlphanumericInsensitive(firstISORisk.hazardSituation, secondISORisk.hazardSituation);
      },
      harm: (firstISORisk, secondISORisk) => {
        return sortByAlphanumericInsensitive(firstISORisk.harm, secondISORisk.harm);
      },
      initialAssessment: (firstISORisk, secondISORisk) => {
        return sortByRiskLevel(riskLevels, firstISORisk.preMitigation.riskLevel, secondISORisk.preMitigation.riskLevel);
      },
      finalAssessment: (firstISORisk, secondISORisk) => {
        return sortByRiskLevel(
          riskLevels,
          firstISORisk.postMitigation?.riskLevel,
          secondISORisk.postMitigation?.riskLevel,
        );
      },
      updated: (firstISORisk, secondISORisk) => {
        return sortByDate(new Date(firstISORisk.updated), new Date(secondISORisk.updated));
      },
      changeControlStatus: (firstISORisk, secondISORisk) => {
        const firstISORiskStatus = getDesignControlStatus(firstISORisk);
        const secondISORiskStatus = getDesignControlStatus(secondISORisk);
        return sortByAlphanumericInsensitive(firstISORiskStatus, secondISORiskStatus);
      },
    },
    designElements ?? [],
  );

  const columns: Array<deprecated.QDataColumn> = [
    {
      Header: 'ID',
      accessor: 'code',
      width: '10%',
    },
    {
      Header: 'Hazard',
      accessor: 'hazard',
    },
    {
      Header: 'Hazardous Situation',
      accessor: 'hazardSituation',
    },
    {
      Header: 'Harm',
      accessor: 'harm',
    },
    {
      Header: 'Initial',
      accessor: 'initialAssessment',
    },
    {
      Header: 'Final',
      accessor: 'finalAssessment',
    },
    {
      Header: 'Last Modified',
      accessor: 'updated',
      type: deprecated.QColumnType.DATE,
    },
    {
      Header: 'Design Control',
      accessor: 'changeControlStatus',
    },
    {
      Header: '',
      accessor: 'menu',
      disableSortBy: true,
    },
  ];

  if (features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES]) {
    columns.splice(1, 0, {
      Header: 'Risk category',
      accessor: 'riskCategory',
    });
  }

  const rows: Array<deprecated.QDataRow> = useMemo(() => {
    return sortedData.map((item) => ({
      ...item,
      code: <QBadge>{item.code}</QBadge>,
      changeControlStatus: (
        <DesignControlTag
          changeControlStatus={item.changeControlStatus}
          itemStatus={item.itemStatus as QualityItemStatus}
        />
      ),
      initialAssessment: <RiskTag label={item.preMitigation.riskLevel} riskLevels={riskLevels} />,
      finalAssessment: <RiskTag label={item.postMitigation?.riskLevel} riskLevels={riskLevels} />,
      hazard: <QTextWithHover dataCy={item.id} value={item.hazard} />,
      hazardSituation: <QTextWithHover value={item.hazardSituation} />,
      harm: <QTextWithHover value={item.harm} />,
      riskCategory: <span data-cy={`${item.id}-category`}>{item.category?.label}</span>,
      menu: permissions[UserPermissions.EDIT_DESIGN_ELEMENTS] &&
        permissions[UserPermissions.DELETE_DESIGN_ELEMENTS] && (
          <ClickPropagationBarrier data-cy={`action-menu`}>
            <QMenuButton variant="icon" buttonLabel="options" iconName="MoreVertical">
              <QMenuItem onClick={() => onEditClick(item.id)} data-cy={`edit-${item.id}`}>
                Edit
              </QMenuItem>
              <QMenuItem color="red.500" onClick={() => setElementToBeDeleted(item)} data-cy={`delete-${item.id}`}>
                Delete
              </QMenuItem>
            </QMenuButton>
          </ClickPropagationBarrier>
        ),
    })) as unknown as Array<deprecated.QDataRow>;
  }, [sortedData, onEditClick, permissions, riskLevels]);

  return (
    <>
      <div data-cy={'risk-iso-table'}>
        <PageTable
          columns={columns}
          data={rows}
          isLoading={isLoading}
          pageSize={25}
          emptyStateComponent={<RiskEmptyState productId={productId} riskSubType={RiskSubType.ISO} />}
          onRowClick={onRowClick}
          defaultSelectedRow={selectedItemId}
          manualSortBy={{
            sortByCallback: handleSortByCallback,
            ...riskTableDefaultSortBy,
          }}
        />
      </div>
      <DeleteConfirmationModal
        onConfirm={deleteElementCallback}
        onClose={closeDeleteElementCallback}
        confirmationDetails={
          elementToBeDeleted
            ? {
                title: <QText>Delete Risk</QText>,
                message: (
                  <>
                    Are you sure you want to delete <b>{elementToBeDeleted.code}</b>?
                  </>
                ),
              }
            : undefined
        }
      />
    </>
  );
};
