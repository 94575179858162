import { z } from "zod";

export const ResourceType = z.object({
  label: z.string(),
  resourceIdentifier: z.string(),
});

export const BasePolicy = z.object({
  codePrefix: z
    .string()
    .min(1, { message: "Min. 1 character" })
    .max(5, { message: "Max. 5 characters" })
    .refine((data) => data.match(/^[A-Z]+$/), "Uppercase only"),
  codeStrategy: z.string(), // get values
  label: z.string().min(1).max(100),
  category: ResourceType.array().optional(),
});

export type BasePolicy = z.infer<typeof BasePolicy>;

const RiskLevel = z.object({
  acceptableLevels: z.string().array(),
  levelMapping: z.string().array().array(),
  levels: z.string().array(),
  probability: z.string().array(),
  severity: z.string().array(),
});

export const FMEAAssessment = z.object({
  scaleMaxValue: z.number(),
  mitigationThreshold: z.number(),
});

export const RiskPolicy = BasePolicy.extend({
  type: z.literal("risk"),
  assessment: z.object({
    iso: RiskLevel,
    fmea: FMEAAssessment.optional(),
  }),
});

export type RiskPolicy = z.infer<typeof RiskPolicy>;

export const isRiskPolicy = (policy: Policy): policy is RiskPolicy => {
  return policy.type === "risk";
};

export const RequirementLevel1Policy = BasePolicy.extend({
  type: z.literal("req1"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel1Policy = z.infer<typeof RequirementLevel1Policy>;

export const RequirementLevel2Policy = BasePolicy.extend({
  type: z.literal("req2"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel2Policy = z.infer<typeof RequirementLevel2Policy>;

export const RequirementLevel3Policy = BasePolicy.extend({
  type: z.literal("req3"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel3Policy = z.infer<typeof RequirementLevel3Policy>;

export const RequirementLevel4Policy = BasePolicy.extend({
  type: z.literal("req4"),
  component: ResourceType.array().optional(),
});
export type RequirementLevel4Policy = z.infer<typeof RequirementLevel4Policy>;

export type RequirementPolicies =
  | RequirementLevel1Policy
  | RequirementLevel2Policy
  | RequirementLevel3Policy
  | RequirementLevel4Policy;

export const isRequirementPolicy = (
  policy: Policy,
): policy is RequirementPolicies => {
  return policy.type.startsWith("req");
};

export const TestCaseLevel1Policy = BasePolicy.extend({
  type: z.literal("testCase1"),
});
export type TestCaseLevel1Policy = z.infer<typeof TestCaseLevel1Policy>;

export const TestCaseLevel2Policy = BasePolicy.extend({
  type: z.literal("testCase2"),
});
export type TestCaseLevel2Policy = z.infer<typeof TestCaseLevel2Policy>;

export const TestCaseLevel3Policy = BasePolicy.extend({
  type: z.literal("testCase3"),
});
export type TestCaseLevel3Policy = z.infer<typeof TestCaseLevel3Policy>;

export const TestCaseLevelPolicy = BasePolicy.extend({
  type: z.literal("testCase"),
});
export type TestCaseLevelPolicy = z.infer<typeof TestCaseLevelPolicy>;

export type TestCasePolicies =
  | TestCaseLevel1Policy
  | TestCaseLevel2Policy
  | TestCaseLevel3Policy
  | TestCaseLevelPolicy;

export const isTestCasePolicy = (
  policy: Policy,
): policy is TestCasePolicies => {
  return policy.type.startsWith("testCase");
};

export const Policy = z.discriminatedUnion("type", [
  RequirementLevel1Policy,
  RequirementLevel2Policy,
  RequirementLevel3Policy,
  RequirementLevel4Policy,
  TestCaseLevel1Policy,
  TestCaseLevel2Policy,
  TestCaseLevel3Policy,
  TestCaseLevelPolicy,
  BasePolicy.extend({
    type: z.literal("testLog"),
  }),
  RiskPolicy,
]);

export type Policy = z.input<typeof Policy>;
