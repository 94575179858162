import React from "react";

type Props = {
  isActive: boolean;
};

export const StepBlocker: React.FC<Props> = ({ children, isActive }) => {
  if (!isActive) {
    return null;
  }

  return <>{children}</>;
};
