import React, { useMemo, useCallback, ChangeEventHandler } from "react";

import { Filtering, QStack, QBox, QInput, QText } from "@qualio/ui-components";

import { Tag } from "../../../types/document";
import { TagWrapper } from "./component/TagWrapper";
import { FILTER_DEFINITIONS } from "./filtering";

type DocumentSearchControlsProps = {
  allTags: readonly Tag[];
  productTagIds?: number[];
};

export const DocumentSearchControls: React.FC<DocumentSearchControlsProps> = ({
  allTags,
  productTagIds,
}) => {
  const { searchTerm, setSearchTerm } =
    Filtering.useFiltering<typeof FILTER_DEFINITIONS>();

  const selectedTags = useMemo(() => {
    if (
      !productTagIds ||
      !productTagIds.length ||
      !allTags ||
      !allTags.length
    ) {
      return [];
    }

    return allTags.filter((tag) => productTagIds.includes(tag.id));
  }, [allTags, productTagIds]);

  const selectedTagNames = useMemo(
    () => selectedTags.map((tag) => tag.name),
    [selectedTags],
  );

  const updateSearchTerm: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearchTerm(e.target.value),
    [setSearchTerm],
  );

  return (
    <QStack direction="column" gap={2}>
      <QStack
        width="100%"
        direction="row"
        paddingBottom={4}
        alignItems="baseline"
      >
        <QText
          flexShrink={0}
          width="auto"
          fontWeight={400}
          fontSize="sm"
          noOfLines={1}
        >
          Documents that contain all of the following tags will appear here.{" "}
        </QText>
        <TagWrapper tags={selectedTagNames} />
      </QStack>
      <QBox w="100%">
        <QInput
          iconLeftName="Search"
          placeholder="Search..."
          value={searchTerm}
          onChange={updateSearchTerm}
        />
      </QBox>
    </QStack>
  );
};
