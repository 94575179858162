import React, { useCallback, useRef } from "react";

import {
  QBodyLayout,
  QButton,
  QFooter,
  QHeader,
  QStep,
  QStepperProgress,
  QTitle,
} from "@qualio/ui-components";
import { useNavigate } from "react-router";

import { StepBlocker } from "../../components/StepBlocker/StepBlocker";
import { CreateProductProvider } from "../../context/CreateProductContext";
import { useCreateProductStore } from "../../hooks/useCreateProductStore";
import { Product, Requirements, Risk, TestCases } from "./steps";

export const CreateProductLayout: React.FC = () => {
  const nextRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { activeStep, isDisabled, previousActiveStep } = useCreateProductStore(
    (state) => ({
      activeStep: state.activeStep,
      isDisabled: state.isDisabled,
      previousActiveStep: state.previousActiveStep,
    }),
  );

  const onCancel = useCallback(() => {
    navigate("/product");
  }, [navigate]);

  return (
    <QBodyLayout.SplitPanelLeftWithStepper
      activeStep={activeStep}
      onStepItemClicked={() => undefined}
    >
      <QHeader>
        <QTitle>Create New Product</QTitle>
      </QHeader>
      <QStepperProgress />
      <QStep title="Product profile" />
      <QStep title="Requirements" />
      <QStep title="Test cases" />
      <QStep title="Risks" />
      <StepBlocker isActive={activeStep === 0}>
        <Product nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 1}>
        <Requirements nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 2}>
        <TestCases nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 3}>
        <Risk nextRef={nextRef} />
      </StepBlocker>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
        >
          Back
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
        >
          Back
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel}>
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
        >
          Back
        </QButton>
        <QButton onClick={() => null}>Create product</QButton>
      </QFooter>
    </QBodyLayout.SplitPanelLeftWithStepper>
  );
};

export const CreateProduct: React.FC = () => {
  return (
    <CreateProductProvider>
      <CreateProductLayout />
    </CreateProductProvider>
  );
};
