import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";

import itemsApi from "../../api/items";
import { useCurrentProduct } from "./useCurrentProduct";

export const useRetrieveDesignElementHistory = (designElementId: string) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: ["REVIEW_HISTORY", companyId, productId, designElementId],
    queryFn: () =>
      itemsApi.getHistoryByItemId(companyId, productId, designElementId),
  });
};
