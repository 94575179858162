import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const Related = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg width="24" height="24" fill="none">
          <path
            fillRule="evenodd"
            d="M7.874 11A4.002 4.002 0 0 0 0 12a4 4 0 0 0 7.874 1h4.258l2.888 4.332a4 4 0 1 0 1.663-1.11L13.87 12l2.814-4.222a4 4 0 1 0-1.663-1.11L12.132 11zM6 12a2 2 0 1 0-4 0 2 2 0 0 0 4 0m12 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m0-16a2 2 0 1 1 0 4 2 2 0 0 1 0-4"
            fill="currentColor"
            color="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

Related.displayName = 'Related';
