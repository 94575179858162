import { QFlex } from '../../QLayouts';
import React from 'react';
import { PersonStatus } from '../../QAtoms';
import { QActivityCardContent } from './ActivityCardContent';

export type ActivityStatus = Exclude<
  PersonStatus,
  'pending' | 'added' | 'removed'
>;

export interface QActivityProps {
  status: ActivityStatus;
  fullName: string;
  actionTimestamp: string;
  /**
   * Set actionLabel only when using the default status for custom action other than commented.
   */
  actionLabel?: string;
}

export const QActivity: React.FC<QActivityProps> = (props) => {
  const { status, fullName, actionTimestamp, children, actionLabel, ...rest } =
    props;

  return (
    <QFlex
      direction="column"
      bg={'gray.50'}
      padding="12px 16px"
      borderRadius="4px"
      border="1px"
      borderColor="gray.100"
      {...rest}
    >
      <QActivityCardContent {...props} />
    </QFlex>
  );
};
