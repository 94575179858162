import { AxiosError } from 'axios';

import { BaseQualityItem, BaseQualityType, TestCaseType } from '../types/baseQualityItem';
import { ChangeHistory } from '../types/changeHistory';
import { ServerSideValidationError, ServerSideValidationErrors } from '../types/form';
import { Payload } from '../types/payload';
import { DataValidationIssue, RequestValidationError } from '../types/requestValidationError';
import { Requirement } from '../types/requirement';
import { TestCaseTrace } from '../types/trace';
import api from './index';

const catchServerSideValidationErrors = (type?: BaseQualityType) => (error: AxiosError<ServerSideValidationErrors>) => {
  if (error.response?.status === 400 && error.response.data?.errors) {
    throw new RequestValidationError(
      type ?? '',
      error.response.data.errors.map(
        (item: ServerSideValidationError) =>
          new DataValidationIssue(
            item.field ? item.field.split('.').pop() ?? '' : '',
            item.message ? item.message[0] ?? '' : '',
          ),
      ),
    );
  }
  throw error;
};

export const designElementApi = {
  deleteById(companyId: number, productId: string, designElementId: string): Promise<void> {
    return api
      .delete(`company/${companyId}/product-development/product/${productId}/qualityItem/${designElementId}`)
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
  deleteRequirementById(companyId: number, productId: string, designElementId: string): Promise<void> {
    return api
      .delete(`company/${companyId}/product-development/product/${productId}/requirements/${designElementId}`)
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
  deleteTestCaseById(companyId: number, productId: string, designElementId: string): Promise<void> {
    return api
      .delete(`company/${companyId}/product-development/product/${productId}/test-cases/${designElementId}`)
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
  deleteRiskById(companyId: number, productId: string, designElementId: string): Promise<void> {
    return api
      .delete(`company/${companyId}/product-development/product/${productId}/risks/${designElementId}`)
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
};

const itemsApi = {
  query<T>(company: number, product: string, itemType: string, subType?: string): Promise<T[]> {
    const params: Record<string, string> = { type: itemType };
    if (subType) {
      params['subType'] = subType;
    }

    return api
      .get(`/company/${company}/product-development/product/${product}/qualityItem`, {
        params,
      })
      .then((res) => res.data as Payload<T[]>)
      .then((res) => res.data)
      .catch(() => []);
  },
  queryById<T>(
    company: number,
    product: string,
    qualityItemId: string,
    qualityItemVersion?: string,
  ): Promise<T | undefined> {
    let url = `/company/${company}/product-development/product/${product}/qualityItem/${qualityItemId}`;
    if (qualityItemVersion !== undefined) {
      url = url + `/version/${qualityItemVersion}`;
    }
    return api
      .get(url)
      .then((res) => res.data as Payload<T>)
      .then((res) => res.data)
      .catch(() => undefined);
  },
  queryTestCases(company: number, product: string, types: Array<TestCaseType>): Promise<TestCaseTrace[]> {
    return Promise.all(
      types.map((itemType) =>
        api.get(`/company/${company}/product-development/product/${product}/qualityItem`, {
          params: {
            type: itemType,
          },
        }),
      ),
    )
      .then((testCases) =>
        testCases.map((testCase): TestCaseTrace[] => (testCase.data as Payload<TestCaseTrace[]>).data),
      )
      .then((testCases) => testCases.reduce((accumulator, value) => accumulator.concat(value), []));
  },
  queryAllRequirements(company: number, product: string): Promise<Requirement[]> {
    return Promise.all(
      ['req1', 'req2', 'req3', 'req4'].map((itemType) =>
        api.get(`/company/${company}/product-development/product/${product}/qualityItem`, {
          params: {
            type: itemType,
          },
        }),
      ),
    )
      .then((requirements) =>
        requirements.map((requirement): Requirement[] => (requirement.data as Payload<Requirement[]>).data),
      )
      .then((requirements) => [...requirements[0], ...requirements[1], ...requirements[2], ...requirements[3]])
      .catch(() => []);
  },
  create(company: number, product: string, body: unknown): Promise<any> {
    return api
      .post(`/company/${company}/product-development/product/${product}/qualityItem`, body)
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
  addTestResult(company: number, product: string, body: Record<string, any>): Promise<any> {
    const { testCases, ...payload } = body.data;
    return api
      .post(`/company/${company}/product-development/product/${product}/test-result`, {
        data: {
          ...payload,
          testCase: testCases[0],
        },
      })
      .then((res) => res.data)
      .catch(catchServerSideValidationErrors());
  },
  update(
    company: number,
    product: string,
    body: Payload<Pick<BaseQualityItem, 'id'> & Record<string, unknown>>,
  ): Promise<any> {
    return api
      .patch(`/company/${company}/product-development/product/${product}/qualityItem/${body.data.id}`, body)
      .then((res) => res.data);
  },
  getHistoryByItemId(company: number, product: string, qualityItemId: string): Promise<ChangeHistory> {
    return api
      .get(`/company/${company}/product-development/product/${product}/qualityItem/${qualityItemId}/history`)
      .then((res) => res.data as Payload<ChangeHistory>)
      .then((res) => res.data);
  },
};

export default itemsApi;
