import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const Section = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg width="24" height="24" fill="none">
          <path
            d="m12.0078 14h-1.8672v-5.15625h-5.2656v5.15625h-1.875v-11.42188h1.875v4.67188h5.2656v-4.67188h1.8672zm4.582 0h-.9218v-3.6758c0-.1224.0013-.2448.0039-.36717.0026-.12239.0052-.24219.0078-.35937.0052-.11719.0117-.22917.0195-.33594-.0547.05989-.1224.125-.2031.19531-.0781.07031-.1615.14323-.25.21875l-.6563.52342-.4609-.58201 1.6953-1.32813h.7656z"
            fill="currentColor"
            color="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

Section.displayName = 'Section';
