import { useToastProvider } from "@qualio/ui-components";
import { useQuery } from "react-query";

import { MedtechAPI } from "../../api/medtech/medtech";
import { useCompanyId } from "../../context/CurrentUserContext";

export const useTags = () => {
  const { showToast } = useToastProvider();
  const companyId = useCompanyId();
  return useQuery({
    queryKey: [companyId, "tags"],
    queryFn: () => MedtechAPI.getDocumentTags(companyId),
    onError: () => {
      showToast({
        title: "Error",
        description: "Unable to fetch document tags",
        status: "error",
      });
    },
  });
};
