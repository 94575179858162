import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stringable } from '../QTypes';

type SetSearchParams = (
  newParams: Record<string, Stringable | Date | null>,
) => void;

export type UseCumulativeSearchParamsResult = [
  Omit<URLSearchParams, 'append' | 'delete' | 'set' | 'sort'>,
  SetSearchParams,
];

/**
 * Functions like `useSearchParams` from `react-router-dom`, with
 * the exception that it will not discard existing search params.
 *
 * A value of `null` will explicitly remove the search param.
 *
 * IMPORANT: This hook does not do any (en/de)coding of values.
 * It's up to the caller to ensure URL safety.
 */
export const useCumulativeSearchParams =
  (): UseCumulativeSearchParamsResult => {
    const [searchParams, setSearchParams] = useSearchParams();

    const set: SetSearchParams = useCallback(
      (newParams) =>
        setSearchParams(
          (sp) => {
            Object.entries(newParams).forEach(([key, value]) => {
              if (value === null) {
                sp.delete(key);
              } else if (Array.isArray(value)) {
                sp.delete(key);
                for (const v of value.filter(Boolean)) {
                  sp.append(key, stringify(v));
                }
              } else {
                sp.set(key, stringify(value));
              }
            });
            return sp;
          },
          {
            replace: true,
          },
        ),
      [setSearchParams],
    );

    return [searchParams, set];
  };

const stringify = (value: Stringable | Date) =>
  value instanceof Date ? value.toISOString() : value?.toString();
