import * as z from "zod";

export const ProductType = z.union([
  z.literal("Physical device only"),
  z.literal("Software only"),
  z.literal("Combination of physical device and software"),
]);

export type ProductType = z.infer<typeof ProductType>;

export const Product = z.object({
  id: z.string(),
  name: z.string().nonempty().max(100),
  type: ProductType.optional(),
  tags: z.number().array().optional(),
});
export type Product = z.infer<typeof Product>;

export const ProductWithoutId = Product.omit({ id: true });
export type ProductWithoutId = z.infer<typeof ProductWithoutId>;
